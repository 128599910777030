import React, { useState, useEffect } from "react";
import "./styles.css";

const CardCount = ({ 
  className = "col-md-3",
  title, 
  count = 0,
  number, 
  icon, 
  color = "primary",
  trend = { value: 5, isPositive: true },
  animate = true
}) => {
  const [displayCount, setDisplayCount] = useState(typeof count === 'number' ? 0 : count);
  const [isAnimated, setIsAnimated] = useState(false);
  
  // Determine default icon based on color if none provided
  const getDefaultIcon = () => {
    const iconMap = {
      primary: "fas fa-chart-line",
      success: "fas fa-check-circle",
      info: "fas fa-info-circle",
      warning: "fas fa-exclamation-triangle",
      danger: "fas fa-times-circle",
      secondary: "fas fa-chart-bar"
    };
    
    return iconMap[color] || "fas fa-chart-line";
  };
  
  // Use provided icon or default
  const displayIcon = icon || getDefaultIcon();
  
  // Animate count on mount (only if count is a number)
  useEffect(() => {
    // If count is not a number or animation is disabled, just set it directly
    if (typeof count !== 'number' || !animate) {
      setDisplayCount(count);
      setIsAnimated(true);
      return;
    }
    
    const duration = 1500;
    const startTime = Date.now();
    const startValue = 0;
    const endValue = isNaN(count) ? 0 : count;

    const animateCount = () => {
      const now = Date.now();
      const elapsed = now - startTime;
      const progress = Math.min(elapsed / duration, 1);

      // Easing function for smoother animation
      const easeOutQuart = (x) => 1 - Math.pow(1 - x, 4);
      const easedProgress = easeOutQuart(progress);

      setDisplayCount(Math.floor(startValue + (endValue - startValue) * easedProgress));

      if (progress < 1) {
        requestAnimationFrame(animateCount);
      }
    };

    animateCount();
    setTimeout(() => setIsAnimated(true), 300);
  }, [count, animate]);

  // Ensure trend value is a valid number
  const safeTrendValue = isNaN(trend?.value) ? 0 : Number(trend?.value);
  const trendIsPositive = trend?.isPositive !== false; // Default to positive if not specified

  // Format the count for display
  const formattedCount = typeof displayCount === 'number' ? 
    displayCount.toLocaleString() : displayCount;

  return (
    <div className={`${className} card-count-wrapper ${isAnimated ? 'animated' : ''}`}>
      <div className={`card-count card-count-${color}`}>
        <div className="card-count-content">
          {/* Top section with icon and trend */}
          <div className="card-count-top">
            <div className={`card-count-icon-wrapper card-count-icon-${color}`}>
              <i className={displayIcon}></i>
            </div>
            
            <div className={`card-count-trend ${trendIsPositive ? 'positive' : 'negative'}`}>
              <i className={trendIsPositive ? 'fas fa-arrow-up' : 'fas fa-arrow-down'}></i> {safeTrendValue}%
            </div>
          </div>
          
          {/* Count and label */}
          <div className="card-count-details">
            <h2 className="card-count-value">{formattedCount}</h2>
            <p className="card-count-title">{title || 'Count'}</p>
          </div>
          
          {/* View details link */}
          <div className="card-count-view-details">
            <span>View details</span>
            <i className="fas fa-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCount;
