import React, { useState, useEffect } from 'react';

const DropdownButton = ({ buttonText, actions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button 
        className="btn btn-sm" 
        style={{
          backgroundColor: '#F79222',
          color: 'white',
          borderRadius: '4px',
          padding: '4px 15px',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          fontSize: '13px',
          fontWeight: '600',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          minWidth: '130px'
        }}
        onClick={toggleDropdown}
      >
        {buttonText} <i className="fa fa-chevron-down" style={{ fontSize: '10px' }}></i>
      </button>
      {isOpen && (
        <div 
          style={{
            position: 'absolute',
            right: 0,
            top: '100%',
            marginTop: '5px',
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            border: '1px solid #eee',
            zIndex: 1000,
            minWidth: '130px',
            padding: '3px 0',
            fontSize: '13px'
          }}
        >
          <div 
            style={{
              position: 'absolute',
              top: '-6px',
              right: '10px',
              width: '12px',
              height: '12px',
              backgroundColor: 'white',
              transform: 'rotate(45deg)',
              borderTop: '1px solid #eee',
              borderLeft: '1px solid #eee',
              borderRadius: '2px',
              zIndex: 999
            }}
          />
          {actions.map((action, index) => (
            <a
              key={index}
              href={action.href || "#"}
              onClick={(e) => {
                if (action.onClick) {
                  e.preventDefault();
                  action.onClick(e);
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '6px 12px',
                color: '#333',
                textDecoration: 'none',
                fontSize: '13px',
                gap: '6px'
              }}
            >
              {action.icon && <i className={`fa ${action.icon}`} style={{ color: action.iconColor }} />} 
              {action.text}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownButton; 