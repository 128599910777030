import React from "react";
import Loans from "./Loan";
import { Filter } from "../../../components/Filter";
import { Loading } from "../../../components/Loader";
import toastMessage from "../../../components/toastMessage";
import axios from "axios";
import HOST_URL from "../../../configs/api";
import moment from "moment";
import LoansTypes from "./LoanTypes";

class MemberStatement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      loans: [],
      isLoading: false,
      end_date: moment().format("YYYY-MM-DD"),
      members: [],
      savings: [],
      loanSummary: {
        loan_types: [],
        loans_data: [],
      },
    };
  }

  componentDidMount = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  };

  onChangeSacco = async (e) => {
    await this.setState({ appid: e, members: [] }, () => {
      this.get_member_loans(true);
    });
  };

  onChangeEndDate(e) {
    e.preventDefault();
    this.setState({ end_date: e.target.value }, () => {
      if (this.state?.appid?.value) {
        this.get_member_loans(true);
        // this.get_member_savings(true);
      }
    });
  }

  get_member_loans(isLoading) {
    this.setState({ isLoading, loans: [] });

    const { start_date, end_date, appid } = this.state;
    let { ztoken } = this.props;
    let params = {};

    if (end_date !== undefined) {
      params["toDate"] = end_date;
    }

    if (appid.value !== undefined) {
      params["app_id"] = appid.value;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/aggregate/loans`,
      params,
      headers: {
        authorization: `Bearer ${ztoken}`,
      },
    };

    axios(options)
      .then((data) => {
        const responseData = data?.data?.data || {};
        this.setState({
          isLoading: false,
          loanSummary: responseData,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  handleResetFilters() {
    this.setState({ end_date: moment().format("YYYY-MM-DD") });
  }

  render() {
    return (
      <div className="content-cont">
        <div className="panel-body" style={{ paddingLeft: 0 }}>
          <Filter
            showResetFilters={true}
            appid={this.state.appid}
            apps={this.state.apps}
            end_date={this.state.end_date}
            response={this.state.response}
            onChangeEndDate={this.onChangeEndDate.bind(this)}
            onChangeSacco={this.onChangeSacco.bind(this)}
            isLoadingMembers={this.state.isLoadingMembers}
            handleResetFilters={this.handleResetFilters.bind(this)}
          />
          <div className="col-md-12">
            {this.state.isLoading ? (
              <Loading message="Please wait..." icon="fa fa-spinner fa-spin" />
            ) : this.state?.appid?.value ? (
              <div className="panel">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <a href="#aggr_loan_data" data-toggle="tab">
                      Loans
                    </a>
                  </li>
                  <li>
                    <a href="#aggr_loan_types" data-toggle="tab">
                      Loan Types
                    </a>
                  </li>
                </ul>
                <div className="panel-heading">
                  <div>
                    <h4>{this.state.loanSummary.organization}</h4>
                    <h4>{this.state.loanSummary.email}</h4>
                    <h4>{this.state.loanSummary.address}</h4>
                    <h4>{this.state.loanSummary.date}</h4>
                  </div>
                </div>
                <div className="panel-body ">
                  <div className="tab-content">
                    <div className="tab-pane active" id="aggr_loan_data">
                      <Loans
                        {...this.props}
                        data={this.state.loanSummary.loans_data}
                        fromDate={this.state.start_date}
                        toDate={this.state.end_date}
                        appid={this.state.appid.value}
                      />
                    </div>
                    <div className="tab-pane" id="aggr_loan_types">
                      <LoansTypes
                        {...this.props}
                        data={this.state.loanSummary.loan_types}
                        fromDate={this.state.start_date}
                        toDate={this.state.end_date}
                        appid={this.state.appid.value}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <center>
                <span style={{ opacity: 0.5 }}>
                  Please choose sacco, Reports will be appear here
                </span>
              </center>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MemberStatement;
