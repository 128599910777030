/* eslint-disable no-unused-vars */
import React from "react";
import AppClass from "../../AppClass";
import axios from "axios";
import HOST_URL from "../../configs/api";
import { Loading } from "../../components/Loader";
import toastMessage from "../../components/toastMessage";
import { Select } from "../../components/Select";

class Member extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmitting: false,
      response: {},
      user_id: "",
      password: "",
      redirect: false,
      isAutoLogin: false,
      user: {},
      apps: [],
      isAppActive: false,
      showPassword: false,
    };
  }

  componentDidMount = async () => {
    const { search } = this.props.location;

    if (search) {
      var str_f = search.substring(1);
      const decData = decodeURI(str_f);
      const finalData = JSON.parse(decData || {});
      let { userProfile, token, apps } = finalData;

      const user = {
        ...userProfile,
        ztoken: token,
        apps,
      };

      this.setState({ isAutoLogin: true });

      const app_id = await AppClass.get_app_ids(apps || []);

      this.register_member(user, app_id);
    }
  };

  onChangeApps(e) {
    let { response } = this.state;
    delete response["app"];
    this.setState({
      app: e,
      response,
    });
  }

  handleLogin = async (e) => {
    e && e.preventDefault();

    this.setState({ isSubmitting: true });

    const { user_id, password } = this.state;

    const data = {
      user: user_id,
      password,
    };

    const errors = AppClass.validate_form(data);

    if (!Object.keys(errors).length === 0) {
    }

    const newLoginStepOne = await this.handleNewLoginStepOne();

    if (newLoginStepOne?.apps?.length > 0) {
      this.setState({
        isAppActive: true,
      });

      return;
    }

    //handle oldLogin
    this.onOldLogin();
  };

  handleNewLoginStepOne = async () => {
    let { user_id, apps } = this.state;

    try {
      const stepOneOptions = {
        method: "POST",
        url: `${HOST_URL.new_api}/login-send-user`,
        data: {
          data: {
            user: user_id,
          },
        },
        timeout: 5000, // Timeout set to 5 seconds
      };

      const userOrg = await axios(stepOneOptions);

      const { data: dataStepOne } = userOrg;

      if (!dataStepOne.success) {
        return;
      }

      if (dataStepOne?.data?.length > 0) {
        apps = dataStepOne.data[0].map((el) => {
          return {
            label: el.name,
            value: el._id,
            ...el,
          };
        });

        this.setState({ apps, isSubmitting: false });

        return { apps };
      }
    } catch (error) {
      return error;
    }
  };

  validateStepTwoLogin() {
    let { response, app } = this.state;

    if (!app) {
      response.app = "Please select sacco";
    }

    this.setState({ response });
  }

  handleNewLoginStepTwo = async (e) => {
    e.preventDefault();
    const { user_id, password, app, response } = this.state;

    await this.validateStepTwoLogin();

    if (Object.keys(response).length === 0) {
      this.setState({ isSubmitting: true });

      const options = {
        method: "POST",
        url: `${HOST_URL.new_api}/login`,
        data: {
          data: {
            user: user_id,
            password: password,
            orgid: app.value,
          },
        },
      };

      axios(options)
        .then(async (res) => {
          let { data, token, modules } = res.data;
          data.ztoken = token;

          const apps = await this.getModulesOnlyHasLoanAndSavingsAccess(
            modules,
            token
          );
          data.apps = apps;

          const app_ids = await AppClass.get_app_ids(apps);
          this.register_member(data, app_ids);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", "Wrong credentials");
        });
    }
  };

  getModulesOnlyHasLoanAndSavingsAccess = async (modules = {}, token) => {
    let apps = [];
    for (let md of Object.keys(modules)) {
      if (modules[md].loansAndSaving) {
        const appInfo = await AppClass.getNewAppById(token, md);

        apps.push({
          label: appInfo.name,
          value: appInfo._id,
          ...appInfo,
        });
      }
    }

    return apps;
  };

  onOldLogin() {
    this.setState({ isSubmitting: true });

    const { user_id, password } = this.state;

    const data = {
      user: user_id,
      password,
    };

    const errors = AppClass.validate_form(data);

    if (!Object.keys(errors).length) {
      let response = {};

      axios({
        method: "POST",
        url: HOST_URL.mobitill_url + "/login",
        data: { data: data },
        timeout: 5000, // Timeout set to 5 seconds
      })
        .then((data) => {
          let user = data.data.data;
          user.ztoken = data.data.token;

          if (!user.name && !user.firstName) {
            user.firstName = user.phone;
            user.lastName = "-";
          }

          this.onSuccess(user);
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          if (err.response && err.response.data) {
            response.error = err.response.data.errors;
          } else {
            response.error = err.error;
          }
          this.setState({
            response,
            isSubmitting: false,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  onSuccess = async (user) => {
    try {
      let apps = [],
        app_ids = [];

      apps =
        user && user.role && user.role.diladmin
          ? await AppClass.get_apps(user.ztoken)
          : await AppClass.get_user_apps(user.ztoken, {
              user: {
                _id: user._id,
              },
            });

      let target =
        user && user.role && user.role.diladmin ? "diladmin" : "subAccess";

      apps = await AppClass.accessedApps(apps.data.data, target); //get apps  have access to

      user.apps = apps;
      app_ids = await AppClass.get_app_ids(apps);

      this.register_member(user, app_ids);
    } catch (error) {
      let response = {};
      response.error = "Error, " + error;
      this.setState({
        response,
        isSubmitting: false,
      });
    }
  };

  register_member(user, app_id) {
    let response = {},
      clientid = undefined;

    if (user.name) {
      let split = user.name.split(" ");

      user.firstName = split[0];
      user.lastName = split.length > 1 ? split[1] : "-";
    }

    if (user.clientid && user.clientid.length > 0) {
      clientid = user.clientid[0].clientid;
      user._id = user.clientid[0].clientid;
      user.client_id = user.clientid[0].clientid;
    }

    const body = {
      model: "register",
      app_id,
      user_name: user.firstName + " " + user.lastName,
      user_id: clientid || user._id,
      phone_number: user.phone,
      clientid,
    };

    AppClass.register(body)
      .then((data) => {
        this.setState({
          apps: data.data.data,
          isSubmitting: false,
          isAutoLogin: false,
        });

        if (this.state.autoUsed) {
          delete user.user_id;
        }

        if (data.data.data.length > 0) {
          user.epesi_loan_saving_account_id = data.data.data[0]._id;
        }

        sessionStorage.setItem("saccoloan", JSON.stringify(user));
        this.setState({
          isSubmitting: false,
          redirect: true,
          user,
        });

        if (user.role && user.role.diladmin && this.state.autoUsed) {
          return (window.location.href = "/home/admin/loans");
        }

        if (
          this.state.autoUsed &&
          user.role &&
          user.role.sales &&
          this.state.autoUsed
        ) {
          return (window.location.href = "/home/admin/loans");
        }

        if (user.role && user.role.tadmin && this.state.autoUsed) {
          return (window.location.href = "/home/admin/loans");
        }

        return (window.location.href = "/home/member/overview");
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
          isAutoLogin: false,
        });
      });
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    // Color palette based on #FDD0D3 with #EF404F as primary button color
    const colors = {
      primary: "#EF404F",      // New primary color for buttons and important elements
      secondary: "#FDD0D3",    // Original color, now used as secondary
      primaryLight: "#FEF5F6", // Very light shade for backgrounds (almost white)
      primaryText: "#9E5A5F",  // Text color that works with the theme
      border: "#F5C1C5",
      error: "#DC3545",
      success: "#28A745",
      dark: "#4A4A4A",
      light: "#FFFFFF",
      gray: "#6C757D"
    };

    const { response, isSubmitting, user_id, password } = this.state;

    return (
      <div style={{ 
        height: "100vh", 
        width: "100vw", 
        overflow: "hidden", 
        position: "fixed", 
        top: 0, 
        left: 0 
      }}>
        {this.state.isAutoLogin ? (
          <Loading icon="fa fa-spinner fa-spin" message="Logging you in..." />
        ) : (
          <div style={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            height: "100%" 
          }}>
            {/* Left Column - Sidebar/Info Section */}
            <div style={{ 
              width: "50%", 
              backgroundColor: colors.primaryLight, 
              boxShadow: "2px 0 15px rgba(0,0,0,0.05)",
              height: "100%",
              overflowY: "auto"
            }}>
              <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                height: "100%" 
              }}>
                <div style={{ 
                  padding: "2rem", 
                  textAlign: "center" 
                }}>
                  <img 
                    src="./loans-and-savings -primary-logo-on-white.svg" 
                    alt="MOBITILL" 
                    style={{ 
                      maxHeight: "60px", 
                      marginBottom: "1rem" 
                    }} 
                  />
                  <h5 style={{ 
                    color: colors.primaryText, 
                    fontWeight: "500",
                    marginBottom: "1.5rem"
                  }}>Member Portal</h5>
                  <div style={{
                    backgroundColor: colors.secondary,
                    padding: "1rem",
                    borderRadius: "0.5rem",
                    marginBottom: "1.5rem"
                  }}>
                    <p style={{ 
                      color: colors.primaryText,
                      fontSize: "1.3rem",
                      marginBottom: "0",
                      lineHeight: "1.5"
                    }}>
                      Welcome to your Loans & Savings portal. Access your accounts, 
                      track loans, and manage savings all in one place.
                    </p>
                  </div>
                </div>

                {/* Member Features Grid */}
                <div style={{ 
                  padding: "0 1.5rem 1.5rem", 
                  flexGrow: 1 
                }}>
                  <h6 style={{
                    color: colors.dark,
                    fontWeight: "600",
                    marginBottom: "1.2rem",
                    fontSize: "1.2rem"
                  }}>MEMBER FEATURES</h6>
                  
                  <div style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "1rem"
                  }}>
                    {/* Feature tiles for member features */}
                    {[
                      { icon: "wallet", title: "Account Balance" },
                      { icon: "money-bill", title: "Loan Status" },
                      { icon: "piggy-bank", title: "Savings Account" },
                      { icon: "history", title: "Transaction History" },
                      { icon: "file-invoice", title: "Monthly Statement" },
                      { icon: "calculator", title: "Loan Calculator" }
                    ].map((feature, index) => (
                      <div key={index} style={{
                        backgroundColor: colors.light,
                        borderRadius: "0.75rem",
                        padding: "1rem",
                        boxShadow: "0 4px 6px rgba(0,0,0,0.04), 0 1px 3px rgba(0,0,0,0.08)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center"
                      }}>
                        <div style={{
                          backgroundColor: colors.secondary,
                          width: "3rem",
                          height: "3rem",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "0.75rem"
                        }}>
                          <i className={`fa fa-${feature.icon}`} style={{ 
                            color: colors.primary, 
                            fontSize: "1.25rem"
                          }}></i>
                        </div>
                        <span style={{ 
                          fontWeight: 600, 
                          color: colors.dark,
                          fontSize: "1.05rem"
                        }}>{feature.title}</span>
                      </div>
                    ))}
                  </div>

                  {/* Help Section */}
                  <div style={{
                    marginTop: "2rem",
                    backgroundColor: colors.light,
                    padding: "1.25rem",
                    borderRadius: "0.75rem",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.04), 0 1px 3px rgba(0,0,0,0.08)"
                  }}>
                    <h6 style={{
                      color: colors.primary,
                      fontWeight: "600",
                      marginBottom: "0.75rem",
                      fontSize: "1.2rem"
                    }}>Need Help?</h6>
                    <p style={{
                      fontSize: "1.1rem",
                      color: colors.dark,
                      marginBottom: "0.75rem",
                      lineHeight: "1.5"
                    }}>
                      Contact your SACCO or our support team for assistance.
                    </p>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.1rem",
                      marginBottom: "0.5rem"
                    }}>
                      <i className="fa fa-envelope" style={{ 
                        color: colors.primary, 
                        marginRight: "0.75rem" 
                      }}></i>
                      <span>support@mobitill.com</span>
                    </div>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.1rem"
                    }}>
                      <i className="fa fa-phone" style={{ 
                        color: colors.primary, 
                        marginRight: "0.75rem" 
                      }}></i>
                      <span>+254 783 060 287</span>
                    </div>
                  </div>
                </div>

                {/* Footer */}
                <div style={{ 
                  padding: "1rem", 
                  textAlign: "center", 
                  color: colors.gray, 
                  fontSize: "1.05rem",
                  boxShadow: "0 -4px 3px rgba(0,0,0,0.02)"
                }}>
                  <p style={{ marginBottom: "0.25rem" }}>Mobitill Group</p>
                  <p style={{ marginBottom: 0 }}>© {new Date().getFullYear()} All Rights Reserved</p>
                </div>
              </div>
            </div>

            {/* Right Column - Login Form */}
            <div style={{ 
              width: "50%", 
              backgroundColor: colors.light, 
              height: "100%" 
            }}>
              <div style={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                height: "100%" 
              }}>
                <div style={{ 
                  width: "80%", 
                  maxWidth: "450px", 
                  backgroundColor: colors.light, 
                  boxShadow: "0 4px 20px rgba(0,0,0,0.08), 0 2px 8px rgba(0,0,0,0.06)",
                  borderRadius: "0.5rem"
                }}>
                  <div style={{ 
                    backgroundColor: colors.secondary, 
                    padding: "1.25rem 1.5rem", 
                    borderTopLeftRadius: "0.5rem",
                    borderTopRightRadius: "0.5rem"
                  }}>
                    <h5 style={{ 
                      marginBottom: 0, 
                      fontWeight: "bold",
                      color: colors.primary,
                      fontSize: "1.4rem"
                    }}>Member Login</h5>
                  </div>
                  <div style={{ padding: "1.5rem" }}>
                    {response.error ? (
                      <div style={{ 
                        backgroundColor: "#FFEAEC", 
                        color: colors.error, 
                        padding: "0.75rem 1.25rem", 
                        marginBottom: "1rem", 
                        borderRadius: "0.25rem",
                        border: `1px solid ${colors.error}`
                      }}>
                        <i className="fa fa-exclamation-circle" style={{ marginRight: "0.5rem" }}></i>
                        <span>{response.error}</span>
                      </div>
                    ) : (
                      response.success && (
                        <div style={{ 
                          backgroundColor: "#D4EDDA", 
                          color: colors.success, 
                          padding: "0.75rem 1.25rem", 
                          marginBottom: "1rem", 
                          borderRadius: "0.25rem",
                          border: `1px solid ${colors.success}`
                        }}>
                          <i className="fa fa-check-circle" style={{ marginRight: "0.5rem" }}></i>
                          <span>{response.success}</span>
                        </div>
                      )
                    )}
                    <form method="post">
                      <div style={{ marginBottom: "1rem" }}>
                        <label style={{ 
                          display: "block", 
                          marginBottom: "0.5rem",
                          color: colors.dark,
                          fontWeight: "500"
                        }}>
                          Email Address <span style={{ color: colors.error }}>*</span>
                        </label>
                        <div style={{ 
                          display: "flex", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.04)",
                          borderRadius: "0.25rem",
                          position: "relative" 
                        }}>
                          <span style={{ 
                            display: "flex", 
                            alignItems: "center", 
                            padding: "0.375rem 0.75rem", 
                            backgroundColor: colors.primaryLight, 
                            boxShadow: "1px 0 3px rgba(0,0,0,0.02)",
                            borderTopLeftRadius: "0.25rem",
                            borderBottomLeftRadius: "0.25rem"
                          }}>
                            <i className="fa fa-user" style={{ color: colors.primary }}></i>
                          </span>
                          <input
                            type="text"
                            style={{ 
                              flex: 1, 
                              padding: "0.375rem 0.75rem", 
                              border: "none", 
                              outline: "none",
                              borderTopRightRadius: "0.25rem",
                              borderBottomRightRadius: "0.25rem",
                              paddingRight: "2.5rem"
                            }}
                            name="user_id"
                            placeholder="Enter your email"
                            value={user_id}
                            onChange={(e) => AppClass.handleformonchange(this, e)}
                          />
                        </div>
                        {response.user_id && (
                          <div style={{ 
                            color: colors.error, 
                            fontSize: "0.875rem", 
                            marginTop: "0.25rem" 
                          }}>{response.user_id}</div>
                        )}
                      </div>

                      <div style={{ marginBottom: "1.5rem" }}>
                        <label style={{ 
                          display: "block", 
                          marginBottom: "0.5rem",
                          color: colors.dark,
                          fontWeight: "500"
                        }}>
                          Password <span style={{ color: colors.error }}>*</span>
                        </label>
                        <div style={{ 
                          display: "flex", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.04)",
                          borderRadius: "0.25rem",
                          position: "relative" 
                        }}>
                          <span style={{ 
                            display: "flex", 
                            alignItems: "center", 
                            padding: "0.375rem 0.75rem", 
                            backgroundColor: colors.primaryLight, 
                            boxShadow: "1px 0 3px rgba(0,0,0,0.02)",
                            borderTopLeftRadius: "0.25rem",
                            borderBottomLeftRadius: "0.25rem"
                          }}>
                            <i className="fa fa-lock" style={{ color: colors.primary }}></i>
                          </span>
                          <input
                            type={this.state.showPassword ? "text" : "password"}
                            style={{ 
                              flex: 1, 
                              padding: "0.375rem 0.75rem", 
                              border: "none", 
                              outline: "none",
                              borderTopRightRadius: "0.25rem",
                              borderBottomRightRadius: "0.25rem",
                              paddingRight: "2.5rem"
                            }}
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => AppClass.handleformonchange(this, e)}
                          />
                          <button
                            type="button"
                            onClick={this.togglePasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "0.75rem",
                              top: "50%",
                              transform: "translateY(-50%)",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              padding: "0.25rem",
                              color: colors.gray
                            }}
                          >
                            <i className={`fa ${this.state.showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                          </button>
                        </div>
                        {response.password && (
                          <div style={{ 
                            color: colors.error, 
                            fontSize: "0.875rem", 
                            marginTop: "0.25rem" 
                          }}>{response.password}</div>
                        )}
                      </div>

                      <div style={{ marginBottom: "1rem" }}>
                        <button
                          disabled={isSubmitting}
                          onClick={this.handleLogin.bind(this)}
                          style={{ 
                            width: "100%", 
                            backgroundColor: colors.primary, 
                            color: colors.light, 
                            border: "none", 
                            padding: "0.625rem 1rem", 
                            borderRadius: "0.25rem", 
                            cursor: isSubmitting ? "not-allowed" : "pointer",
                            opacity: isSubmitting ? 0.65 : 1,
                            fontWeight: "500",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
                          }}
                          type="button"
                        >
                          {isSubmitting ? (
                            <>
                              <i className="fa fa-spinner fa-spin" style={{ marginRight: "0.5rem" }}></i>
                              Please wait...
                            </>
                          ) : (
                            "Log in"
                          )}
                        </button>
                      </div>
                      
                      <div style={{ textAlign: "center" }}>
                        <a href="/adminAuth" style={{ 
                          textDecoration: "none", 
                          color: colors.primary,
                          fontWeight: "500"
                        }}>
                          <i className="fa fa-arrow-right" style={{ marginRight: "0.25rem" }}></i> Go to Admin Login
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Member;
