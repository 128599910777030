import React from "react";
import { MessageView } from "../../components/Messages";

class MessageViewScreen extends React.Component {
  render() {
    console.log('----message id ', this.props.messageId)
    return (
      <div>
        <MessageView
          apps={this.props.apps}
          token={this.props.ztoken}
          role={this.props.role}
          user_id={this.props.user_id}
          messageId={this.props.messageId}
        />
      </div>
    );
  }
}

export default MessageViewScreen;
