import React, { useState } from "react";
import { Card } from "../Card";
import moment from "moment";
import getTotalFees from "../../utils/getTotalFees";
import { numberWithCommas } from "../../configs/formatCurrency";
import DropdownButton from '../DropdownButton';

const ApplicationList = ({
  role,
  loans,
  apps,
  delete_data,
  view_purpose,
  handleOtherColumn,
  other_column,
  user_id,
}) => {
  const [activeDropdown, setActiveDropdown] = useState(null);



  // Close dropdown when clicking outside
  React.useEffect(() => {
    const handleClickOutside = () => {
      setActiveDropdown(null);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  
  return (
    <Card className="col-md-12">
      <div className="panel-heading card-title">
        <span>Applications</span>
      </div>
      {role === "admin" ? (
        <div
          className="panel-body"
          style={{
            padding: "15px",
            overflowX: "auto",
          }}
        >
          <table
            className="table table-bordered"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              overflowX: "auto",
            }}
          >
            <thead>
              <tr>
                <th>#ID</th>
                <th>Borrower</th>
                <th>Principal Amount</th>
                <th>Interest</th>
                <th>Fees</th>
                <th>Amount Payable</th>
                <th>Purpose</th>
                <th>Payment Duration</th>
                {other_column === "Number Tyre" && <th>Number Tyre</th>}
                <th>Status</th>
                <th>Date</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loans.length > 0 ? (
                loans.map((item, i) => {
                  const adminActions = (item) => [
                    {
                      text: 'View',
                      icon: 'fa-eye',
                      iconColor: '#F79222',
                      href: `view_loan_application/${item._id}`
                    },
                    ...(item.status === "Pending" && item.createdBy === user_id ? [
                      {
                        text: 'Edit',
                        icon: 'fa-edit',
                        iconColor: '#F79222',
                        href: `/home/admin/apply_loan/${item._id}`
                      },
                      {
                        text: 'Delete',
                        icon: 'fa-trash',
                        iconColor: '#F9394C',
                        onClick: (e) => delete_data(e, item._id)
                      }
                    ] : [])
                  ];

                  return (
                    <tr key={i}>
                      <td>{item.loan_id}</td>
                      <td>{item.user_name}</td>
                      <td>KES {numberWithCommas(item.req_amount)}</td>
                      <td>KES {numberWithCommas(item.expected_interest)}</td>
                      <td>KES {getTotalFees(item.fees)}</td>
                      <td>KES {numberWithCommas(item.disbursed_amount)}</td>
                      <td>{item.purpose}</td>
                      <td>{item.payment_duration} Month(s)</td>
                      {other_column === "Number Tyre" && (
                        <td>{item.number_tyres}</td>
                      )}
                      <td className="text-success">{item.status}</td>
                      <td>{moment(item.date).format("lll")}</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                        <DropdownButton 
                          buttonText="Select Actions"
                          actions={adminActions(item)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={12} align="center">
                    No data here
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="panel-body" style={{
          padding: "15px",
          overflowX: "auto"
        }}>
          <table className="table table-bordered" style={{ 
              width: "100%", borderCollapse: "collapse",
              overflowX: "auto"
            }}>
            <thead>
              <tr>
                <th>Sacco</th>
                <th>Requested Amount</th>
                <th>Purpose</th>
                <th>Amount Payable</th>
                <th>Payment Duration</th>
                <th>Status</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loans.length > 0 ? (
                loans.map((item, i) => {
                  const userActions = (item) => [
                    {
                      text: 'View',
                      icon: 'fa-eye',
                      iconColor: '#F79222',
                      href: `loandetails/${item._id}`
                    },
                    {
                      text: 'Delete',
                      icon: 'fa-trash',
                      iconColor: '#F9394C',
                      onClick: (e) => delete_data(e, item._id)
                    }
                  ];

                  return (
                    <tr key={i}>
                      <td onClick={() => (window.location.href = `loandetails/${item._id}`)}>
                        {item.organization}
                      </td>
                      <td onClick={() => (window.location.href = `loandetails/${item._id}`)}>
                        KES {numberWithCommas(parseFloat(item.req_amount))}
                      </td>
                      <td onClick={() => (window.location.href = `loandetails/${item._id}`)}>
                        {item.purpose}
                      </td>
                      <td onClick={() => (window.location.href = `loandetails/${item._id}`)}>
                        {numberWithCommas(item.disbursed_amount)}
                      </td>
                      <td onClick={() => (window.location.href = `loandetails/${item._id}`)}>
                        {item.payment_duration} Month(s)
                      </td>
                      <td 
                        onClick={() => (window.location.href = `loandetails/${item._id}`)}
                        className="text-primary status"
                      >
                        {item.status}
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                        <DropdownButton 
                          buttonText="Select Action"
                          actions={userActions(item)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7} align="center">
                    No data here
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
};

export default ApplicationList;
