import React from "react";
import { numberWithCommas } from "../../../configs/formatCurrency";
import CardCount from "../../../components/CardCount/CardCount";
import HOST_URL from "../../../configs/api";
import Axios from "axios";
import toastMessage from "../../../components/toastMessage";
import { saveAs } from "file-saver";
class Loans extends React.Component {
  state = {};
  handleShowTransaction(i) {
    const key = "showTrans_" + i;

    this.setState({
      [key]: !this.state[key],
    });
  }

  handleGenerateReport(type='pdf') {
    this.setState({ isSubmitting: true });
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/${this.props.memberId}/loans/${type}`,
      responseType: "blob",
      params: {
        fromDate: this.props.fromDate,
        toDate: this.props.toDate,
      },
      headers: {
        authorization: `Bearer ${this.props.ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };

    Axios(options)
      .then((data) => {
        this.downloadFile(data.data, type);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error",
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Unable to generate loan statement report"
        );
      });
  }

  downloadFile(file, type='pdf') {
    try {
      toastMessage("success", "Downloaded successful");
      this.setState({ isSubmitting: false });
      const fileBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        fileBlob,
        this.props.memberName +
          "" +
          this.props.fromDate +
          "" +
          this.props.toDate +
          `.${type}`
      );
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("Error downloading file:", JSON.stringify(error));
    }
  }
  render() {
    return (
      <>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => this.handleGenerateReport()} //PDF report download
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download PDF Report"}
          </button>
          <button
            className="btn csv-btn"
            onClick={() => this.handleGenerateReport('csv')}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download CSV Report"}
          </button>
        </div>
        <br />
        <div className="row">
          <CardCount
            count={this.props.summary.numberOfLoans}
            title="Number of Loans"
            className="col-md-4"
          />
          <CardCount
            count={
              "KES " + numberWithCommas(this.props.summary.totalOutstandingBal)
            }
            title="Outstanding Balance"
            className="col-md-4"
          />
          <CardCount
            count={"KES " + numberWithCommas(this.props.summary.totalPayments)}
            title="Total Payments"
            className="col-md-4"
          />
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Loan Id</th>
                <th>Purpose</th>
                <th>Payment Duration</th>
                <th>Principal</th>
                <th>Amount Payable</th>
                <th>Fees</th>
                <th>Interest</th>
                <th>Total Payments</th>
                <th>Loan Balance</th>
              </tr>
            </thead>
            <tbody>
              {this.props.loans.map((el, i) => {
                return (
                  <>
                    <tr key={i}>
                      <td>{el.loanId}</td>
                      <td>{el.purpose}</td>
                      <td>{el.paymentDuration}</td>
                      <td>KES {numberWithCommas(el.principal)}</td>
                      <td>KES {numberWithCommas(el.amountPayable)}</td>
                      <td>KES {numberWithCommas(el.fees)}</td>
                      <td>KES {numberWithCommas(el.interest)}</td>
                      <td>KES {numberWithCommas(el.totalPayments)}</td>
                      <td>KES {numberWithCommas(el.loanBalance)}</td>
                    </tr>
                    <div className="panel">
                      <div className="panel-heading">
                        <button
                          className="btn btn-link "
                          onClick={() => this.handleShowTransaction(i)}
                          style={{ border: "transparent" }}
                        >
                          <b>
                            {this.state["showTrans_" + i] ? "Hide" : "View "} Transactions
                          </b>
                        </button>
                      </div>
                      {this.state["showTrans_" + i] && (
                        <div className="panel-body">
                          <div>
                            {el.transactions && el.transactions?.length > 0 && (
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Transaction Id</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {el.transactions.map((trans, t) => {
                                    return (
                                      <tr key={t}>
                                        <td>{trans.date}</td>
                                        <td>{trans.tranId}</td>
                                        <td>KES {numberWithCommas(trans.amount)}</td>
                                        <td>KES {numberWithCommas(trans.balance)}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Loans;
