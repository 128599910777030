import React from 'react';
import PropTypes from 'prop-types';

const STATUS_CONFIG = {
  sent: {
    icon: 'fa-check-circle',
    color: '#2e7d32',
    background: '#e8f5e9',
    label: 'Sent'
  },
  draft: {
    icon: 'fa-edit',
    color: '#1976d2',
    background: '#e3f2fd',
    label: 'Draft'
  },
  failed: {
    icon: 'fa-exclamation-circle',
    color: '#c62828',
    background: '#ffebee',
    label: 'Failed'
  },
  pending: {
    icon: 'fa-clock',
    color: '#ed6c02',
    background: '#fff3e0',
    label: 'Pending'
  }
};

const MessageStatus = ({ status, showLabel = true }) => {
  const config = STATUS_CONFIG[status.toLowerCase()] || STATUS_CONFIG.pending;
  
  return (
    <span 
      className={`status-badge status-${status.toLowerCase()}`}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '6px',
        background: config.background,
        color: config.color,
        padding: '4px 12px',
        borderRadius: '16px',
        fontSize: '12px',
        fontWeight: '500'
      }}
    >
      <i className={`fas ${config.icon}`}></i>
      {showLabel && config.label}
    </span>
  );
};

MessageStatus.propTypes = {
  status: PropTypes.string.isRequired,
  showLabel: PropTypes.bool
};

export default MessageStatus; 