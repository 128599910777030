import { object } from "prop-types";
import { toast } from "react-toastify";

export default function toastMessage(type = "error", message) {
  let toastMessage = message
  if (message instanceof object) {
    toastMessage = message.response?.data?.error || message.response?.data?.message
      || message.response?.message || message.message || 'An error occurred';
  }

  return toast[type](toastMessage, {
    position: "bottom-right",
  });
}
