import React from "react";
import { TransactionReversalForm } from "../../../components/Saving";
import { Loading } from "../../../components/Loader";
import toastMessage from "../../../components/toastMessage";
import axios from "axios";

class TransactionReversal extends React.Component {
  constructor() {
    super();

    this.state = {
      error: {},
      data: [],
      isSubmitting: false,
      balance: 0,
      tickno: "",
    };
  }

  componentDidMount() {
    console.log("this props****", this.props);

    if (this.props.savingInfo) {
      this.setState({
        tickno: this.props.savingInfo.tickno,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.savingInfo !== prevProps.savingInfo) {
      this.setState({
        tickno: this.props.savingInfo.tickno,
      });
    }
  }

  validateForm() {
    let { error } = this.state;

    if (!this.state.tickno || this.state.tickno === 0) {
      error.tickno = "Transaction is is required";
      this.setState({ error, isSubmitting: false });
      return;
    }

    this.setState({ error });
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    let value = e.target ? e.target.value : e;

    this.setState({ error, [field]: value });
  }

  handleSaveData = async () => {
    await this.validateForm();
    let { error, tickno } = this.state;

    if (Object.keys(error).length > 0) return;

    this.setState({ isSubmitting: true });

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_URL}/savings/reversal`,
      data: {
        tickno,
      },
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
        });
        toastMessage("success", "Reversal transaction is success");
        this.setState({
          isSubmitting: false,
        });

        this.props.getData();
        this.props.handleCloseModal();
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", err?.response?.data?.error || err?.response?.message);
      });
  };

  render() {
    const { error, tickno, isSubmitting, isLoading } = this.state;

    return (
      <div className="content-cont">
        {isLoading ? (
          <Loading icon="fa fa-spinner fa-spin" />
        ) : (
          <TransactionReversalForm
            error={error}
            id={this.props.id}
            onChangeText={this.onChangeText.bind(this)}
            tickno={tickno}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            handleSaveData={this.handleSaveData.bind(this)}
            savingInfo={this.props.savingInfo || {}}
          />
        )}
      </div>
    );
  }
}

export default TransactionReversal;
