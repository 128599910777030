import React from "react";
import { ViewLoanPayments } from "../components/Loan";
import { Loading } from "../components/Loader";
import AppClass from "../AppClass";
import moment from "moment";
import { Modal, Header, Body, Footer } from "../components/Modal";
import { Filter } from "../components/Filter";
import toastMessage from "../components/toastMessage";
import { Pagination } from "../components/Pagination";
import CardCount from "../components/CardCount/CardCount";
import { numberWithCommas } from "../configs/formatCurrency";

class LoanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loanData: {},
      status: "",
      apps: props.apps,
      sacco: "",
      isSubmitting: false,
      payments: [],
      id: "",
      show: false,
      files: [],
      loan_id: "",
      start_date: moment().subtract("365", "days").format("YYYY-MM-DD"),
      end_date: moment().add("1", "d").format("YYYY-MM-DD"),
      summary: {},
      page: 1,
      limit: 50,
      topUpAmount: "",
      showTopUpConfirmation: false,
      topUpError: "",
      isFocused: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        loan_id: this.props.somethingID,
      },
      () => {
        this.get_loan_by_id(true);
      }
    );
  }

  get_loan_by_id(isLoading) {
    this.setState({ isLoading });
    const { loan_id } = this.state;

    AppClass.get_loan_by_loan_id(loan_id)
      .then((res) => {
        const loanData = res.data?.data;

        this.setState({
          isLoading: false,
          status: loanData.status,
          id: loanData._id,
          loanData,
        });

        this.getPayments();
      })
      .catch((err) => {
        toastMessage(
          "error",
          err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Failed to get loan details'
        );
        this.setState({
          isLoading: false,
        });
      });
  }

  getPayments(isLoadingPayment) {
    const { start_date, end_date, loan_id, page, limit } = this.state;

    this.setState({
      isLoadingPayment,
    });

    const body = {
      loan_id,
      pageNumber: page,
      pageLimit: limit,
    };

    if (start_date && end_date) {
      body["fromDate"] = start_date;
      body["toDate"] = end_date;
    }

    AppClass.get_repaymentdata(body)
      .then((res) => {
        const { data, summary } = res.data;

        this.setState({
          isLoadingPayment: false,
          payments: data,
          summary,
        });
      })
      .catch((err) => {
        toastMessage("error",
          err?.response?.data?.message ||
          err?.response?.data?.error ||
          err?.messsage ||
          'Failed to get payments'
        );
        this.setState({
          isLoadingPayment: false,
        });
      });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value }, () => {
      this.getPayments(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value }, () => {
      this.getPayments(true);
    });
  }

  onDelete(e, id) {
    e.preventDefault();
    if (window.confirm("Are you sure to delete this payment?")) {
      this.delete_payment(id);
    }
  }

  delete_payment(id) {
    const input_data = {
      id,
      model: "loan",
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors)?.length) {
      AppClass.delete_data(input_data)
        .then((data) => {
          this.setState({
            isLoading: false,
          });
          this.setState({
            payments: [],
          });
          this.get_loan_by_id(this.props.somethingID);
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          toastMessage(
            "error",
            err?.response?.data?.message ||
            err?.response?.data?.error ||
            err?.messsage ||
            'Failed to delete payment'
          );
        });
    } else {
      toastMessage(
        "error",
        errors,
        'Failed to delete payment'
      );
    }
  }

  handleOpenModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleClose(modal) {
    this.setState({ [modal]: false });
  }

  handleChangeLimit(e) {
    this.setState(
      {
        limit: e.target.value,
        page: 1,
      },
      () => {
        this.getPayments(true);
      }
    );
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getPayments(true);
      }
    );
  }

  handleTopUpAmountChange = (e) => {
    this.setState({ topUpAmount: e.target.value });
  }

  validateTopUpAmount = () => {
    const { topUpAmount } = this.state;
    if (!topUpAmount) {
      this.setState({ topUpError: "Amount is required" });
      return false;
    }
    if (isNaN(topUpAmount) || parseFloat(topUpAmount) <= 0) {
      this.setState({ topUpError: "Please enter a valid amount" });
      return false;
    }
    this.setState({ topUpError: "" });
    return true;
  }

  showTopUpConfirmation = () => {
    if (this.validateTopUpAmount()) {
      this.setState({ showTopUpConfirmation: true });
    }
  }

  submitTopUp = () => {
    this.setState({ 
      showTopUpModal: false, 
      showTopUpConfirmation: false,
      topUpAmount: ""
    }, () => {
      toastMessage("success", "Top-up submitted successfully");
      this.get_loan_by_id(true);
    });
  }

  render() {
    const {
      data,
      isLoading,
      message,
      apps,
      payments,
      id,
      loanData
    } = this.state;
    let sacco =
      this.state.data?.length > 0
        ? AppClass.get_app_by_id(apps, data[0].app_id).name
        : "";

    return (
      <div className="content-cont">
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : (
          <>
            <div style={{ paddingTop: 15 }}>
              <Filter
                start_date={this.state.start_date}
                end_date={this.state.end_date}
                onChangeStartingDate={this.onChangeStartingDate.bind(this)}
                onChangeEndDate={this.onChangeEndDate.bind(this)}
              />
              <div className="col-md-12 ">
                <div className="row">
                  <CardCount
                    count={this.state.summary.noOfrepayments}
                    title="Number of Repayments"
                    className="col-md-4"
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(this.state.summary.totalrepayments)
                    }
                    title="Total Repayments"
                    className="col-md-4"
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(
                        this.state.summary.totalCumulativerepayments
                      )
                    }
                    title="Total Cumulative Repayments"
                    className="col-md-4"
                  />
                </div>
              </div>
              <ViewLoanPayments
                loanData={this.state.loanData}
                data={data}
                apps={apps}
                payments={payments}
                role={this.props.role}
                sacco={sacco}
                id={id}
                loan_id={this.props.somethingID}
                delete_payment={this.onDelete.bind(this)}
                handleOpenModal={() => this.handleOpenModal("showTopUpModal")}
                show={this.state.showTopUpModal}
                isLoadingPayment={this.state.isLoadingPayment}
              />
              <Pagination
                totalItemsCount={this.state.payments?.length}
                page={this.state.page}
                limit={this.state.limit}
                handleChangeLimit={this.handleChangeLimit.bind(this)}
                handlePagination={this.handlePagination.bind(this)}
              />
            </div>
          </>
        )}

        <Modal show={this.state.showTopUpModal}>
          <Header>
            <h5 className="modal-title" style={{ fontWeight: 600 }}>
              Loan Top-Up
              <small style={{ 
                color: '#6c757d', 
                marginLeft: '8px', 
                fontWeight: 600,
                fontSize: '16px',
                backgroundColor: '#f0f0f0',
                padding: '4px 10px',
                borderRadius: '4px',
                display: 'inline-block'
              }}>{this.props.somethingID}</small>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleClose("showTopUpModal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            {!this.state.showTopUpConfirmation ? (
              <div style={{ padding: '0 5px' }}>
                <p style={{ color: '#6c757d', marginBottom: '20px' }}>
                  Top up <span style={{ 
                    fontWeight: 600, 
                    color: '#F79222',
                    backgroundColor: '#fff8e1',
                    padding: '2px 6px',
                    borderRadius: '3px'
                  }}>{this.state.loanData?.user_name + "'s" || "your"}</span> loan with additional funds.
                </p>
                
                <div style={{ 
                  backgroundColor: '#f8f9fa', 
                  borderRadius: '8px', 
                  marginBottom: '20px',
                  border: '1px solid #dee2e6',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}>
                  <div style={{ padding: '15px' }}>
                    <div style={{ fontSize: '13px', color: '#6c757d', marginBottom: '5px' }}>Current Balance</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h3 style={{ fontWeight: 700, marginBottom: 0 }}>KES {numberWithCommas(loanData?.amount)}</h3>
                      {this.state.loanData && (
                        <span style={{ 
                          backgroundColor: '#fff8e1', 
                          color: '#F79222', 
                          padding: '4px 10px', 
                          borderRadius: '20px', 
                          fontSize: '12px',
                          fontWeight: 600,
                          display: 'inline-flex',
                          alignItems: 'center',
                          border: '1px solid #F79222'
                        }}>
                          <span style={{
                            width: '6px',
                            height: '6px',
                            backgroundColor: '#F79222',
                            borderRadius: '50%',
                            marginRight: '5px',
                            display: 'inline-block'
                          }}></span>
                          {loanData?.status || "Active"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div style={{ marginBottom: '20px' }}>
                  <label style={{ color: '#333', marginBottom: '8px', display: 'block', fontWeight: 600 }}>Enter Top-Up Amount (KES)</label>
                  <div style={{ 
                    display: 'flex',
                    position: 'relative',
                    boxShadow: this.state.isFocused ? '0 0 0 0.2rem rgba(247, 146, 34, 0.25)' : 'none',
                    borderRadius: '4px',
                    transition: 'box-shadow 0.15s ease-in-out'
                  }}>
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      backgroundColor: 'white', 
                      border: '1px solid #ced4da',
                      borderRight: 'none', 
                      borderTopLeftRadius: '4px', 
                      borderBottomLeftRadius: '4px', 
                      padding: '0 12px',
                      borderColor: this.state.isFocused ? '#F79222' : '#ced4da',
                      position: 'relative'
                    }}>
                      <span style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#adb5bd',
                        letterSpacing: '0.5px'
                      }}>KES</span>
                      <span style={{
                        position: 'absolute',
                        right: 0,
                        top: '12px',
                        bottom: '12px',
                        width: '1px',
                        backgroundColor: '#e9ecef'
                      }}></span>
                    </div>
                    <input
                      type="number"
                      style={{ 
                        flex: 1, 
                        height: '45px', 
                        padding: '10px 12px', 
                        fontSize: '16px', 
                        border: '1px solid #ced4da', 
                        borderLeft: 'none',
                        borderRight: 'none',
                        outline: 'none',
                        borderColor: this.state.isFocused ? '#F79222' : '#ced4da'
                      }}
                      placeholder="0"
                      value={this.state.topUpAmount}
                      onChange={this.handleTopUpAmountChange}
                      onFocus={() => this.setState({ isFocused: true })}
                      onBlur={() => this.setState({ isFocused: false })}
                    />
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      backgroundColor: 'white', 
                      border: '1px solid #ced4da', 
                      borderLeft: 'none', 
                      borderTopRightRadius: '4px', 
                      borderBottomRightRadius: '4px', 
                      padding: '0 12px',
                      borderColor: this.state.isFocused ? '#F79222' : '#ced4da'
                    }}>
                      <i className="fa fa-question-circle" style={{ color: '#6c757d' }}></i>
                    </div>
                  </div>
                  {this.state.topUpError && (
                    <small style={{ color: '#F9394C', marginTop: '5px', display: 'block' }}>
                      {this.state.topUpError}
                    </small>
                  )}
                </div>
                
                {this.state.topUpAmount && !isNaN(this.state.topUpAmount) && (
                  <div style={{ 
                    backgroundColor: '#f8f9fa', 
                    borderRadius: '4px', 
                    marginBottom: '20px',
                    borderLeft: '4px solid #F79222',
                    border: '1px solid #dee2e6',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                  }}>
                    <div style={{ padding: '15px' }}>
                      <div style={{ fontSize: '13px', color: '#6c757d', marginBottom: '5px' }}>New Balance After Top-Up</div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3 style={{ fontWeight: 700, marginBottom: 0 }}>KES {numberWithCommas(parseFloat(loanData?.balance) + parseFloat(this.state.topUpAmount))}</h3>
                        <span style={{ color: '#F79222' }}>
                          <i className="fa fa-arrow-up" style={{ marginRight: '3px' }}></i> 
                          {Math.round((parseFloat(this.state.topUpAmount) / (parseFloat(loanData?.balance) || 1)) * 100)}%
                        </span>
                      </div>
                      <div style={{ marginTop: '10px', backgroundColor: '#e9ecef', height: '8px', borderRadius: '4px', overflow: 'hidden' }}>
                        <div 
                          style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#F79222'
                          }}>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                <div style={{ 
                  backgroundColor: '#f8f9fa', 
                  borderRadius: '8px', 
                  marginBottom: '15px',
                  border: '1px solid #dee2e6',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}>
                  <div style={{ padding: '15px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <i className="fa fa-info-circle" style={{ color: '#F79222', marginRight: '8px' }}></i>
                      <span style={{ fontWeight: 600 }}>Important Information</span>
                    </div>
                    <ul style={{ paddingLeft: '20px', marginBottom: 0, color: '#6c757d' }}>
                      <li style={{ marginBottom: '5px' }}>This will update the loan balance immediately upon confirmation</li>
                      <li style={{ marginBottom: '5px' }}>No additional interest will be applied to the top-up amount</li>
                      <li>Additional terms and conditions may apply</li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ padding: '0 5px' }}>
                <div style={{ 
                  backgroundColor: '#fff8e1', 
                  color: '#F79222', 
                  padding: '12px 15px', 
                  borderRadius: '4px', 
                  marginBottom: '20px',
                  border: '1px solid #F79222'
                }}>
                  <i className="fa fa-info-circle" style={{ marginRight: '8px' }}></i>
                  Please review and confirm your top-up request.
                </div>
                
                <div style={{ 
                  backgroundColor: '#f8f9fa', 
                  borderRadius: '4px', 
                  marginBottom: '15px',
                  border: '1px solid #dee2e6',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}>
                  <div style={{ padding: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                      <div style={{ color: '#6c757d' }}>Loan ID:</div>
                      <div style={{ fontWeight: 600 }}>{this.props.somethingID}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                      <div style={{ color: '#6c757d' }}>Current Balance:</div>
                      <div style={{ fontWeight: 600 }}>KES {numberWithCommas(loanData?.balance)}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                      <div style={{ color: '#6c757d' }}>Top-Up Amount:</div>
                      <div style={{ fontWeight: 600 }}>KES {numberWithCommas(this.state.topUpAmount)}</div>
                    </div>
                    <hr style={{ margin: '12px 0' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ color: '#6c757d' }}>New Total Balance:</div>
                      <div style={{ fontWeight: 600, color: '#F79222' }}>
                        KES {numberWithCommas(parseFloat(loanData?.balance) + parseFloat(this.state.topUpAmount))}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div style={{ 
                  backgroundColor: '#fff8e1', 
                  color: '#F79222', 
                  padding: '12px 15px', 
                  borderRadius: '4px',
                  border: '1px solid #F79222'
                }}>
                  <i className="fa fa-exclamation-triangle" style={{ marginRight: '8px' }}></i>
                  By confirming, you agree to the terms and conditions of the top-up.
                </div>
              </div>
            )}
          </Body>
          <Footer>
            {!this.state.showTopUpConfirmation ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <button
                  type="button"
                  className="btn-cancel"
                  style={{ 
                    backgroundColor: '#f8f9fa',
                    borderColor: '#ddd',
                    color: '#333',
                    padding: '6px 12px', 
                    borderRadius: '4px',
                    marginRight: '8px',
                    fontWeight: 500,
                    transition: 'all 0.2s ease',
                    border: '1px solid #ddd'
                  }}
                  onClick={() => this.handleClose("showTopUpModal")}
                >
                  <i className="fa fa-times" style={{ marginRight: '5px' }}></i> Cancel
                </button>
                <button
                  type="button"
                  className="btn-send"
                  style={{ 
                    backgroundColor: '#F79222', 
                    border: 'none', 
                    color: 'white', 
                    padding: '6px 12px', 
                    borderRadius: '4px',
                    opacity: (!this.state.topUpAmount || isNaN(this.state.topUpAmount)) ? 0.65 : 1,
                    cursor: (!this.state.topUpAmount || isNaN(this.state.topUpAmount)) ? 'not-allowed' : 'pointer',
                    fontWeight: 600,
                    transition: 'all 0.2s ease'
                  }}
                  onClick={this.showTopUpConfirmation}
                  disabled={!this.state.topUpAmount || isNaN(this.state.topUpAmount)}
                >
                  Next <i className="fa fa-arrow-right" style={{ marginLeft: '5px' }}></i>
                </button>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <button
                  type="button"
                  className="btn-cancel"
                  style={{ 
                    backgroundColor: '#f8f9fa',
                    borderColor: '#ddd',
                    color: '#333',
                    padding: '6px 12px', 
                    borderRadius: '4px',
                    marginRight: '8px',
                    fontWeight: 500,
                    transition: 'all 0.2s ease',
                    border: '1px solid #ddd'
                  }}
                  onClick={() => this.setState({ showTopUpConfirmation: false })}
                >
                  <i className="fa fa-arrow-left" style={{ marginRight: '5px' }}></i> Back
                </button>
                <button
                  type="button"
                  className="btn-send"
                  style={{ 
                    backgroundColor: '#F79222', 
                    border: 'none', 
                    color: 'white', 
                    padding: '6px 12px', 
                    borderRadius: '4px',
                    fontWeight: 600,
                    transition: 'all 0.2s ease'
                  }}
                  onClick={this.submitTopUp}
                >
                  Confirm Top-Up
                </button>
              </div>
            )}
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default LoanDetails;
