import HOST_URL from "./configs/api";
import axios from "axios";

class AppClass {
  static getParams(that, name) {
    return that.props.match.params !== undefined
      ? that.props.match.params[name]
      : "";
  }

  //validate form
  static validate_form = (input_data) => {
    const keys = Object.keys(input_data);
    const errors = {};
    keys.map((key) => {
      //form email & phone validation
      let re_email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let re_phone =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

      if (input_data[key] === undefined || input_data[key] === "") {
        return (errors[key] = key + " is required");
      } else if (key === "email" && !re_email.test(input_data["email"])) {
        return (errors["email"] = " invalid email address");
      } else if (key === "contact" && !re_phone.test(input_data["contact"])) {
        return (errors["contact"] = " invalid phone number");
      }
    });
    return errors;
  };

  // forms on change
  static handleformonchange = (that, e) => {
    const { name, value, checked, error = {} } = e.target;
    const { operation } = that.state;
    delete error[name];
    that.setState({
      [name]: checked ? checked : value,
      response: {},
      disabled: operation === "Deposit",
    });
  };

  //add the comma for the thousands
  static addCommanForThousands(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // session

  static signMeOut = () => {
    sessionStorage.clear();
  };

  static get_user_sessions = () => {
    let session = {};
    if (sessionStorage.getItem("saccoloan") !== undefined) {
      session = JSON.parse(sessionStorage.getItem("saccoloan"));
    }
    return session;
  };

  // server apis

  static get_apps = (token, data) => {
    return axios({
      method: "get",
      url: HOST_URL.mobitill_url + "/apps",
      data,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  };

  static get_user_apps = (token, data) => {
    return axios({
      method: "post",
      url: HOST_URL.mobitill_url + "/apps/accessDataPhone",
      data,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  };

  static accessedApps(apps, target_key) {
    let data = [];

    //target has 3 status `diladmin`, `admin`. `subAccess`

    if (target_key === "diladmin") {
      return apps;
    }

    //check if is admin or subAccess
    for (let i = 0; i < apps.length; i++) {
      let appData = {
        ...(apps[i].app || apps[i]),
      };

      if (target_key === "admin" && apps[i].admin) {
        data.push({
          _id: apps[i].appid,
          orgid: appData.orgid,
          name: appData.name,
          admin: apps[i].admin,
        });
      }

      if (target_key === "subAccess") {
        data.push({
          _id: apps[i].appid,
          orgid: appData.orgid,
          name: appData.name,
        });
      }
    }

    return data;
  }

  static get_app_ids = (apps, target) => {
    let app_ids = [];
    if (!apps) return [];
    for (let i = 0; i < apps.length; i++) {
      if (apps[i] && apps[i].value) {
        app_ids.push(apps[i].value);
      } else if (apps[i] && apps[i].name && apps[i] && apps[i]._id) {
        app_ids.push(apps[i]._id);
      } else if (target === "other_format") {
        app_ids.push(apps[i].appid);
      } else if (apps[i]) {
        app_ids.push(apps[i]?.appid || apps[i]?._id);
      }
    }

    return app_ids;
  };

  static get_app_by_id(apps, _id) {
    let app = {};

    for (let i = 0; i < apps.length; i++) {
      if (apps[i]._id === _id) {
        app = apps[i];
      }
    }

    return app;
  }

  static filter_by_sacco = (data, app_id) => {
    let results = [];

    if (!app_id) {
      return data;
    }

    for (var i = 0; i < data.length; i++) {
      if (app_id.value && data[i].app_id === app_id.value) {
        results.push(data[i]);
      } else if (!app_id.value && data[i].app_id === app_id) {
        results.push(data[i]);
      }
    }

    return results;
  };

  static filter_by_loan = (data, loan_id) => {
    let results = [];

    if (!loan_id) {
      return data;
    }

    for (let i = 0; i < data.length; i++) {
      if (loan_id.value && data[i].loan_id === loan_id.value) {
        results.push(data[i]);
      }
      if (!loan_id.value && data[i].loan_id === loan_id) {
        results.push(data[i]);
      }
    }

    return results;
  };

  static filter_by_user = (data, user_id) => {
    let results = [];

    if (!user_id || user_id === "All" || user_id === "") {
      return data;
    }

    for (var i = 0; i < data.length; i++) {
      if (user_id.value && data[i].user_id === user_id.value) {
        results.push(data[i]);
      } else if (data[i].user_id === user_id) {
        results.push(data[i]);
      }
    }

    return results;
  };

  //member
  static register = (data) => {
    // let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/register", data);
  };

  static get_member = (data) => {
    let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/get_registered_account", data, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  // Loan api

  static get_data = (data) => {
    let { ztoken } = this.get_user_sessions();
    const options = {
      method: "POST",
      url: HOST_URL.url + "/get_loandata",
      data,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  static get_loandata = (data) => {
    let { ztoken } = this.get_user_sessions();

    if (data.app_id) {
      data.app_id = JSON.stringify(data.app_id);
    }

    if (data.status) {
      data.status = JSON.stringify(data.status);
    }
    const options = {
      url: HOST_URL.url + "/loans/",
      params: data,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  static get_repaymentdata = (data) => {
    let { ztoken } = this.get_user_sessions();

    if (data.app_id) {
      data.app_id = JSON.stringify(data.app_id);
    }

    if (data.status) {
      data.status = JSON.stringify(data.status);
    }

    const options = {
      url: HOST_URL.url + "/repayments/",
      params: data,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };

    return axios(options);
  };

  static add_data = (data, _id) => {
    console.log("----in add data");
    let { ztoken } = this.get_user_sessions();
    const keys = Object.keys(data);
    let formData = new FormData();
    keys.map((key) => {
      formData.append(key, data[key]);
      return formData;
    });

    let route = "/add_loandata";

    if (_id && _id !== "") {
      route = "/update_loandata";
      formData.append("id", _id);
    }

    const options = {
      method: "POST",
      url: HOST_URL.url + route,
      data: formData,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    console.log("----in add data, just before");

    return axios(options);
  };

  static update_data = (data) => {
    let { ztoken } = this.get_user_sessions();
    const keys = Object.keys(data);
    let formData = new FormData();
    keys.map((key) => {
      return formData.append(key, data[key]);
    });

    const options = {
      method: "POST",
      url: HOST_URL.url + "/update_loandata",
      data: formData,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  static delete_data = (data) => {
    let { ztoken } = this.get_user_sessions();
    const options = {
      method: "POST",
      url: HOST_URL.url + "/delete_loandata",
      data,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  // Saving api

  static get_savedata = (data) => {
    console.log("...getting save data", data);
    let { ztoken } = this.get_user_sessions();
    const options = {
      method: "POST",
      url: HOST_URL.url + "/get_savedata",
      data,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  static get_save = (data) => {
    let { ztoken } = this.get_user_sessions();

    if (data.app_id) {
      data.app_id = JSON.stringify(data.app_id);
    }

    const options = {
      method: "GET",
      url: HOST_URL.url + "/savings/",
      params: data,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  static get_save_interestrate = (data) => {
    let { ztoken } = this.get_user_sessions();

    const options = {
      method: "POST",
      url: HOST_URL.url + "/get_savedata",
      data,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  static savedata = (data) => {
    let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/get_savedata", data, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  static add_savedata = (data) => {
    // const keys = Object.keys(data);
    // let formData = new FormData();
    // keys.map((key) => {
    //   return formData.append(key, data[key]);
    // });

    // return axios.post(HOST_URL.url + "/add_savedata", formData);
    let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/autoadd_savedata", data, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  static update_savedata = (data) => {
    let { ztoken } = this.get_user_sessions();
    const keys = Object.keys(data);
    let formData = new FormData();
    keys.map((key) => {
      return formData.append(
        key,
        typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key]
      );
    });
    return axios.post(HOST_URL.url + "/update_savedata", formData, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  static delete_savedata = (data) => {
    let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/delete_savedata", data, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  //sms api
  static sendSMS(data) {
    let { ztoken } = this.get_user_sessions();
    return axios({
      method: "post",
      url: HOST_URL.sms_api,
      data,
      config: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Beare ${ztoken}`,
        },
      },
    });
  }

  //system monitoring api
  static monitoring(data) {
    let { ztoken } = this.get_user_sessions();
    return axios({
      method: "post",
      url: "https://monitoring.mobitill.com/api/activity/add_activity",
      data,
      config: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ztoken}`,
        },
      },
    });
  }

  static add_setting_data = (data) => {
    let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/add_settingdata", data, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  static update_setting_data = (data) => {
    let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/update_settingdata", data, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  static get_setting_data = (data) => {
    let { ztoken } = this.get_user_sessions();
    return axios.post(HOST_URL.url + "/get_settingdata", data, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  static update_registered_account = (data) => {
    let { ztoken } = this.get_user_sessions();
    const keys = Object.keys(data);
    let formData = new FormData();
    keys.map((key) => {
      return { key: data[key] };
    });
    return axios.post(HOST_URL.url + "/update_registered_account", formData, {
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    });
  };

  static getSaccoClients = async (app_id, token) => {
    // const config = {
    //   method: "GET",
    //   url: "http://localhost:8891/clients?0[]=appid&1[]=" + app_id,
    //   headers: {
    //     Authorization: "Bearer " + token,
    //   },
    // };
    // const sacco_members = await axios(config);
    // return sacco_members.data.data;
    // const members = await getRegisteredMembers([], app_id);
    // console.log(members);
    // return members;
  };

  static getRegisteredMembers = async (app_id, user_id) => {
    let sess = this.get_user_sessions();
    let from_dil = false;

    if (sess?.token) {
      from_dil = sess.diladmin || false;
    }

    let params = {
      app_id: [app_id],
      from_dil: false,
    };

    if (user_id && !from_dil) {
      params.tag = "by_user";
      // params.user_id = user_id;
    }

    const registered_members = await AppClass.get_member(params);
    const { data } = registered_members.data;

    let members = [];

    for (let el of data) {
      let label = `${el.user_name} ${el.phone_number || ""} `;

      if (el?.user_name?.indexOf(el.phone_number) !== -1) {
        label = el.phone_number;
      }

      if (el.user_name)
        members.push({
          label,
          value: el.user_id,
          ...el,
        });
    }

    return members;
  };

  static getLoanId = async (app_id) => {
    let { ztoken } = this.get_user_sessions();

    const options = {
      method: "GET",
      url: HOST_URL.url + "/loan-id",
      params: {
        app_id,
      },
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };
    return axios(options);
  };

  static getProductById = async (token, productId) => {
    if (!token) {
      const session = this.get_user_sessions();
      token = session.ztoken;
    }
    const product = await axios({
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products/${productId}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return product;
  };

  static getNewAppById = async (token, app_id) => {
    const data = await axios({
      method: "GET",
      url: `https://api.production.mobitill.com/apps/${app_id}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return data.data.data;
  };
}

export default AppClass;
