import React from "react";
import { Card } from "../../Card";
import moment from "moment";
import { Payment } from "../../Payments";
import { numberWithCommas } from "../../../configs/formatCurrency";
import { Loading } from "../../Loader";
import getTotalFees from "../../../utils/getTotalFees";

const ViewLoanPayments = ({
  loanData,
  payments,
  role,
  response,
  csvPayment,
  delete_payment,
  view_files,
  loan_id,
  handleOpenModal,
  isLoadingPayment,
}) => {
  const currentBalance = parseFloat(loanData.balance) || 0;
  
  const calculateProgress = () => {
    if (!loanData) return 0;
    const totalPayable = parseFloat(loanData.disbursed_amount) || 0;
    return totalPayable > 0 ? Math.min(100, (loanData?.total_payment / totalPayable) * 100) : 0;
  };
  
  const progressPercentage = calculateProgress();
  const isFullyPaid = progressPercentage >= 100;
  const endDate = moment(loanData?.date).add(loanData?.payment_duration, 'months');

  // Get status icon based on loan status
  const getStatusIcon = (status) => {
    switch(status?.toLowerCase()) {
      case 'active':
        return 'fa-check-circle';
      case 'pending':
        return 'fa-clock';
      case 'completed':
        return 'fa-check-double';
      case 'defaulted':
        return 'fa-exclamation-triangle';
      case 'cleared':
        return 'fa-check-double';
      case 'disbursed':
        return 'fa-money-bill-wave';
      default:
        return 'fa-info-circle';
    }
  };
  
  // Get status badge class based on loan status
  const getStatusBadgeClass = (status) => {
    switch(status?.toLowerCase()) {
      case 'active':
        return 'status-active';
      case 'pending':
        return 'status-pending';
      case 'completed':
        return 'status-completed';
      case 'defaulted':
        return 'status-defaulted';
      case 'cleared':
        return 'status-cleared';
      case 'disbursed':
        return 'status-disbursed';
      default:
        return 'status-default';
    }
  };
  
  return (
    <div className="loan-payment-container">
      <Card className="loan-info-card">
        <div className="card-title">
          <div className="title-section">
            <i className="fa fa-info-circle title-icon"></i>
            <span>Loan Information</span>
          </div>
          
          <div className="loan-actions">
            <button 
              className="action-button top-up-button"
              onClick={() => handleOpenModal('topup', loanData)}
            >
              <i className="fa fa-plus-circle"></i>
              <span className="top-up-text" 
              style={{
                color: 'white',
              }}
              >Top Up Loan</span>
            </button>
          </div>
        </div>
        
        <div className="loan-summary-section">
          <div className="section-header">
            <h4>Loan Summary</h4>
            <hr />
          </div>
          
          <div className="summary-grid">
            <div className="summary-item">
              <div className="item-label">Loan ID</div>
              <div className="item-value">{loanData?.loan_id}</div>
            </div>
            
            <div className="summary-item">
              <div className="item-label">Loan Type</div>
              <div className="item-value">{loanData?.purpose || "General"}</div>
            </div>
            
            <div className="summary-item">
              <div className="item-label">Member</div>
              <div className="item-value">{loanData?.user_name?.toUpperCase()}</div>
            </div>

            <div className="summary-item">
              <div className="item-label">Member No</div>
              <div className="item-value">{loanData?.member_no}</div>
            </div>
            
            <div className="summary-item">
              <div className="item-label">Sacco</div>
              <div className="item-value">{loanData?.organization}</div>
            </div>

            <div className="summary-item">
              <div className="item-label">Date Created</div>
              <div className="item-value">
                {loanData?.date ? moment(loanData?.created_at).format("MMM D, YYYY") : "N/A"}
              </div>
            </div>
            
            <div className="summary-item">
              <div className="item-label">Date Disbursed</div>
              <div className="item-value">
                {loanData?.date ? moment(loanData?.date).format("MMM D, YYYY") : "N/A"}
              </div>
            </div>
            
            <div className="summary-item">
              <div className="item-label">Status</div>
              <div className="item-value">
                <div className={`status-badge ${getStatusBadgeClass(loanData.status)}`}>
                  <i className={`fa ${getStatusIcon(loanData.status)} status-icon`}></i>
                  <span className="status-text">{loanData.status || "Active"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="financial-details-section">
          <div className="section-header">
            <h4>Financial Details</h4>
            <hr />
          </div>
          
          <div className="financial-grid">
            <div className="financial-item">
              <div className="item-label">Principal</div>
              <div className="item-value">KES {numberWithCommas(loanData?.req_amount || 0)}</div>
            </div>
            
            <div className="financial-item">
              <div className="item-label">Interest</div>
              <div className="item-value">
                KES {numberWithCommas(loanData?.expected_interest || 0)}
              </div>
            </div>
            
            <div className="financial-item">
              <div className="item-label">Fees</div>
              <div className="item-value">
                KES {numberWithCommas(getTotalFees(loanData?.fees))}
              </div>
            </div>
            
            <div className="financial-item">
              <div className="item-label">Amount Payable</div>
              <div className="item-value">KES {numberWithCommas(loanData.disbursed_amount || 0)}</div>
            </div>
            
            <div className="financial-item">
              <div className="item-label">Amount Paid</div>
              <div className="item-value">KES {numberWithCommas(loanData?.total_payment)}</div>
            </div>
            
            <div className="financial-item">
              <div className="item-label">Current Balance</div>
              <div className="item-value">KES {numberWithCommas(currentBalance)}</div>
            </div>
            
            <div className="financial-item">
              <div className="item-label">Payment Period</div>
              <div className="item-value">
                {loanData.payment_duration || loanData.duration || "N/A"} Months
              </div>
            </div>
            
            <div className="financial-item">
              <div className="item-label">Payment Frequency</div>
              <div className="item-value">{loanData.payment_frequency || "Monthly"}</div>
            </div>
          </div>
        </div>
        
        <div className="repayment-progress-section">
          <div className="section-header">
            <h4>Repayment Progress</h4>
            <hr />
          </div>
          
          <div className="progress-details">
            <div className="progress-text">
              <div>
                KES {numberWithCommas(loanData?.total_payment)} of KES {numberWithCommas(loanData.disbursed_amount || 0)}
              </div>
              <div className="progress-status">
                {isFullyPaid ? (
                  <span className="status-paid">Fully Paid</span>
                ) : (
                  <span>{Math.round(progressPercentage)}% repaid</span>
                )}
              </div>
            </div>
            
            <div className="progress-bar-container">
              <div 
                className="progress-bar" 
                style={{width: `${progressPercentage}%`}}
              ></div>
            </div>
            
            <div className="days-remaining">
              {isFullyPaid ? (
                "0 months remaining"
              ) : (
                endDate ? 
                  `${moment(endDate).diff(moment(), 'months')} months remaining` : 
                  "Months remaining: calculating..."
              )}
            </div>
          </div>
        </div>
        
        {loanData.guaranted_amount && Object.keys(loanData.guaranted_amount).length > 0 && (
          <div className="guarantor-section">
            <div className="section-header">
              <h4>Guarantor Information</h4>
              <hr />
            </div>
            
            <table className="guarantor-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Guaranteed Amount</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(loanData.guaranted_amount).map((g, i) => (
                  <tr key={i}>
                    <td>{loanData.guaranted_amount[g].name}</td>
                    <td>KES {numberWithCommas(loanData.guaranted_amount[g].amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Card>
      
      {/* Use the original Payment component for payment data */}
      <div className="payment-section">
        {isLoadingPayment ? (
          <div className="loading-container">
            <Loading icon="fa fa-spinner fa-spin" />
          </div>
        ) : (
          <Payment
            payments={payments}
            role={role}
            response={response}
            loan_id={loan_id}
            showAddPayment={false}
            csvPayment={csvPayment}
            delete_payment={delete_payment}
            view_files={view_files}
            className="payment-component"
          />
        )}
      </div>
      
      <style jsx>{`
        .loan-payment-container {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          color: #333;
          width: 100%;
          height: 100%;
          padding: 20px;
          display: flex;
          flex-direction: column;
        }
        
        .loan-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
        }
        
        .title-section {
          display: flex;
          align-items: center;
        }
        
        .title-icon {
          color: #333;
          font-size: 1.2rem;
          margin-right: 10px;
        }
        
        .loan-title {
          font-size: 24px;
          font-weight: 600;
          margin: 0;
        }
        
        .loan-id {
          color: #666;
          font-weight: normal;
          margin-left: 8px;
        }
        
        .loan-actions {
          display: flex;
          gap: 12px;
        }
        
        .action-button {
          padding: 10px 16px;
          border-radius: 4px;
          font-weight: 500;
          cursor: pointer;
          border: none;
          font-size: 14px;
          transition: all 0.2s ease;
          text-decoration: none;
          display: inline-flex;
          align-items: center;
        }
        
        .action-button i {
          margin-right: 8px;
        }
        
        .download-button {
          background-color: #f8f9fa;
          color: #333;
          border: 1px solid #ddd;
        }
        
        .download-button:hover {
          background-color: #e9ecef;
          transform: translateY(-1px);
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        
        .top-up-button {
          background-color: #F79222;
          color: white;
        }
        
        .top-up-text {
          color: white;
        }
        
        .top-up-button:hover {
          background-color: #e58420;
          transform: translateY(-1px);
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        
        .loan-info-card {
          background: white;
          border-radius: 8px;
          box-shadow: 0 1px 3px rgba(0,0,0,0.1);
          overflow: hidden;
          border: 1px solid #e9ecef;
          margin-bottom: 24px;
        }
        
        .card-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          background-color: #f8f9fa;
          border-bottom: 2px solid #F9394C;
        }
        
        .status-badge {
          display: inline-flex;
          align-items: center;
          padding: 5px 10px 5px 15px;
          border-radius: 30px;
          font-size: 13px;
          font-weight: 600;
          transition: all 0.2s ease;
          box-shadow: 0 1px 3px rgba(0,0,0,0.1);
          width: auto;
          max-width: fit-content;
        }
        
        .status-badge:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        }
        
        .status-badge.status-active {
          background-color: #e8f5e9;
          color: #2e7d32;
          border: 1px solid #4caf50;
        }
        
        .status-badge.status-pending {
          background-color: #fff8e1;
          border: 1px solid #F79222;
          color: #F79222;
        }
        
        .status-badge.status-completed {
          background-color: #e3f2fd;
          color: #1565c0;
          border: 1px solid #2196f3;
        }
        
        .status-badge.status-defaulted {
          background-color: #ffebee;
          color: #F9394C;
          border: 1px solid #F9394C;
        }
        
        .status-badge.status-cleared {
          background-color: #e0f2f1;
          color: #00695c;
          border: 1px solid #009688;
        }
        
        .status-badge.status-disbursed {
          background-color: #fff8e1;
          color: #F79222;
          border: 1px solid #F79222;
        }
        
        .status-badge.status-default {
          background-color: #f5f5f5;
          color: #616161;
          border: 1px solid #9e9e9e;
        }
        
        .status-icon {
          margin-right: 8px;
          font-size: 14px;
        }
        
        .status-text {
          letter-spacing: 0.5px;
        }
        
        .section-header {
          margin-bottom: 20px;
        }
        
        .section-header h4 {
          color: #F9394C;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 8px;
        }
        
        .section-header hr {
          margin-top: 0;
          border-color: #e9ecef;
        }
        
        .loan-summary-section, .financial-details-section, .repayment-progress-section, .guarantor-section {
          padding: 24px;
          border-bottom: 1px solid #e9ecef;
        }
        
        .summary-grid, .financial-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 24px;
        }
        
        .summary-item, .financial-item {
          margin-bottom: 8px;
          padding: 16px;
          background-color: white;
          border-radius: 6px;
          box-shadow: 0 1px 2px rgba(0,0,0,0.05);
          transition: all 0.2s ease;
          border: 1px solid #dee2e6;
        }
        
        .summary-item:hover, .financial-item:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        }
        
        .item-label {
          color: #6c757d;
          font-size: 14px;
          margin-bottom: 8px;
          font-weight: 500;
        }
        
        .item-value {
          font-size: 16px;
          font-weight: 600;
          color: #212529;
        }
        
        .duration-details {
          font-size: 13px;
          font-weight: normal;
          color: #6c757d;
          margin-top: 4px;
        }
        
        .progress-details {
          margin-top: 16px;
          padding: 16px;
          background-color: white;
          border-radius: 6px;
          box-shadow: 0 1px 2px rgba(0,0,0,0.05);
          border: 1px solid #dee2e6;
        }
        
        .progress-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          font-weight: 500;
        }
        
        .progress-status {
          font-weight: 600;
        }
        
        .status-paid {
          color: #28a745;
        }
        
        .progress-bar-container {
          height: 10px;
          background-color: #e9ecef;
          border-radius: 5px;
          overflow: hidden;
          margin-bottom: 12px;
        }
        
        .progress-bar {
          height: 100%;
          background-color: #F79222;
          border-radius: 5px;
          transition: width 0.6s ease;
        }
        
        .days-remaining {
          text-align: right;
          font-size: 14px;
          color: #6c757d;
        }
        
        .guarantor-table {
          width: 100%;
          border-collapse: collapse;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: 0 1px 2px rgba(0,0,0,0.05);
        }
        
        .guarantor-table th {
          background-color: #f8f9fa;
          text-align: left;
          padding: 14px 16px;
          font-weight: 600;
          color: #495057;
          border-bottom: 2px solid #dee2e6;
        }
        
        .guarantor-table td {
          padding: 14px 16px;
          border-bottom: 1px solid #dee2e6;
          background-color: white;
        }
        
        .loading-container {
          display: flex;
          justify-content: center;
          padding: 40px 0;
        }
        
        .payment-section {
          margin-top: 24px;
        }
        
        .payment-component {
          margin-top: 0;
        }
      `}</style>
    </div>
  );
};

export default ViewLoanPayments;
