import React from "react";
import { Messages } from "../../components/Messages";

class MessagesScreen extends React.Component {
  render() {
    return (
      <div>
        <Messages
          apps={this.props.apps}
          token={this.props.ztoken}
          role={this.props.role}
          user_id={this.props.user_id}
        />
      </div>
    );
  }
}

export default MessagesScreen;
