import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import HOST_URL from '../../configs/api';
import { Loading } from '../Loader';
import toastMessage from '../toastMessage';
import './messageView.css';
import MessageStatus from './MessageStatus';

const MessageView = ({ token, messageId }) => {
  const [message, setMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchMessageDetails = async () => {
      try {
        const response = await Axios({
          method: 'GET',
          url: `${HOST_URL.url}/messages/${messageId}`,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        setMessage(response?.data?.data);
      } catch (error) {
        toastMessage('error', error?.response?.data?.message || 'Failed to fetch message details');
      } finally {
        setLoading(false);
      }
    };

    fetchMessageDetails();
  }, [messageId, token]);

  if (loading) {
    return <Loading icon="fa fa-spinner fa-spin" />;
  }

  return (
    <div className="message-view-container">
      <div className="message-view-header">
        <div className="header-left" style={{ 
          borderRadius: '16px', 
          padding: '8px 16px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          backgroundColor: 'white',
          margin: '8px 0'
        }}>
          <button 
            className="btn btn-outline back-button"
            onClick={() => window.location.href = `/home/admin/messages`}
            style={{ 
              border: '1px solid #ffc107',
              color: '#ffc107',
              backgroundColor: 'transparent',
              transition: 'all 0.3s ease',
              padding: '6px 12px',
              borderRadius: '8px'
            }}
          >
            <i className="fas fa-arrow-left" style={{ verticalAlign: 'middle', marginRight: '5px' }}></i> 
            Back
          </button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ margin: 'auto', padding: '4px 12px', color: '#424242' }}>Message Details</h2>
            <MessageStatus status={message?.status} />
          </div>
        </div>
      </div>

      <div className="message-view-content">
        <div className="message-details-row">
          <div className="message-info-card">
            <h3>Message Content</h3>
            <div className="message-text">{message?.content}</div>
            <div className="message-metadata">
              <div>
                <i className="fas fa-user"></i>
                <span className="message-metadata-span">
                  Created by:
                </span> {message?.created_by?.user_name}
              </div>
              <div>
                <i className="fas fa-clock"></i>
                <span className="message-metadata-span">
                  Created:
                </span> {moment(message?.created_at).format('lll')}
              </div>
              {message?.sent_at && (
                <div>
                  <i className="fas fa-paper-plane"></i>
                  <span className="message-metadata-span">
                    Sent:
                  </span> {moment(message?.sent_at).format('lll')}
                </div>
              )}
            </div>
          </div>

          <div className="message-statistics">
            <div className="stats-card">
              <div className="stat-item">
                <h4>Total Recipients</h4>
                <div className="stat-value">
                  <i className="fas fa-users"></i>
                  <span>{message?.recipients?.length || 0}</span>
                </div>
              </div>
              <div className="stat-item">
                <h4>Delivery Status</h4>
                <div className="status-breakdown">
                  {Object.entries(
                    message?.recipients?.reduce((acc, recipient) => {
                      acc[recipient.status] = (acc[recipient.status] || 0) + 1;
                      return acc;
                    }, {}) || {}
                  ).map(([status, count]) => (
                    <div key={status} className="status-item">
                      <MessageStatus status={status} />
                      <span className="status-count">{count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="recipients-section">
          <h3>Recipients</h3>
          <div className="recipients-table-container">
            <table className="recipients-table">
              <thead>
                <tr>
                  <th>Phone Number</th>
                  <th>Status</th>
                  <th>Sent At</th>
                </tr>
              </thead>
              <tbody>
                {message?.recipients?.map((recipient, index) => (
                  <tr key={index}>
                    <td>{recipient?.phone}</td>
                    <td>
                      <MessageStatus status={recipient?.status} />
                    </td>
                    <td>{recipient?.sent_at ? moment(recipient?.sent_at).format('lll') : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageView;