import React, { useState } from "react";
import { Card } from "../Card";
import moment from "moment";
import { numberWithCommas } from "../../configs/formatCurrency";
import Axios from "axios";
import HOST_URL from "../../configs/api";
import toastMessage from "../toastMessage";
import { saveAs } from "file-saver";

const Payment = ({
  response,
  className,
  role,
  payments,
  loan_id,
  showAddPayment,
  fromDate,
  toDate,
  app_id,
  ztoken,
  user_id,
}) => {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const downloadFile = (file, type) => {
    try {
      toastMessage("success", "Downloaded successfully");
      setIsSubmitting(false);
      const fileBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        fileBlob,
        `payments_${fromDate}_${toDate}.${type}`
      );
    } catch (error) {
      toastMessage("error",
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        "Unable to generate repayments statement report"
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleGenerateReport = (type='csv') => {
    setIsSubmitting(true);
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/repayments/${type}`,
      responseType: "blob",
      params: {
        fromDate,
        toDate,
        app_id: JSON.stringify([app_id]),
      },
      headers: {
        authorization: `Bearer ${ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };
    if (user_id) {
      options.params.user_id = user_id;
    }

    Axios(options)
      .then((data) => {
        downloadFile(data.data, type);
      })
      .catch((error) => {
        toastMessage("error",
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Unable to generate repayments statement report"
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <div className="payment-container">
      <Card className={`payment-card ${className}`}>
        {response?.error ? (
          <div className="alert alert-danger">
            <span>{response?.error}</span>
          </div>
        ) : (
          response?.success && (
            <div className="alert alert-success">
              <span>{response?.success}</span>
            </div>
          )
        )}
        <div className="card-title">
          <div className="title-section">
            <i className="fa fa-money-bill-wave title-icon"></i>
            <span>Payments {loan_id && `for Loan: ${loan_id}`}</span>
          </div>
          <div className="card-actions">
            <button 
              className="action-button download-button"
              onClick={() => handleGenerateReport('csv')}
              disabled={isSubmitting}
            >
              <i className="fa fa-download"></i>
              {isSubmitting ? "Downloading..." : "Download CSV"} 
            </button>
          </div>
        </div>
        <div className="payment-table-container">
          <table className="payment-table">
            <thead>
              <tr>
                <th>Reference</th>
                <th>Transaction Date</th>
                <th>System Date</th>
                <th>Amount</th>
                <th>Total Payment</th>
                <th>Current Balance</th>
                <th>Loan ID</th>
                <th>Member</th>
                <th>Cashier</th>
                <th>Device ID</th>
              </tr>
            </thead>
            <tbody>
              {payments.length > 0 ? (
                payments.map((item, i) => (
                  <tr key={i}>
                    <td>{item.tickno}</td>
                    <td>{moment(item.payment_date).format("ll")}</td>
                    <td>{moment(item.date).format("ll")}</td>
                    <td>KES {numberWithCommas(item.last_payment)}</td>
                    <td>
                      <b>KES {numberWithCommas(item.total_payment)}</b>
                    </td>
                    <td>
                      <b>KES {numberWithCommas(item.amount)}</b>
                    </td>
                    <td>
                      <a
                        href={`/home/admin/view_loan_payments/${item.loan_id}`}
                        className="loan-link"
                      >
                        {item.loan_id}
                      </a>
                    </td>
                    <td>{item.user_name.toUpperCase()}</td>                   
                    <td>{item.cashier}</td>
                    <td>{item.deviceId}</td>
                  </tr>
                ))
              ) : (
                <tr className="no-data-row">
                  <td colSpan={10}>
                    <div className="no-data-message">
                      <i className="fa fa-info-circle mr-2"></i>
                      No payment records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
      
      <style jsx>{`
        .payment-container {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          color: #333;
          width: 100%;
        }
        
        .payment-card {
          background: white;
          border-radius: 8px;
          box-shadow: 0 1px 3px rgba(0,0,0,0.1);
          overflow: hidden;
          border: 1px solid #e9ecef;
          margin-bottom: 24px;
        }
        
        .card-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px;
          background-color: #f8f9fa;
          border-bottom: 2px solid #F9394C;
        }
        
        .title-section {
          display: flex;
          align-items: center;
        }
        
        .title-icon {
          color: #333;
          font-size: 1.2rem;
          margin-right: 10px;
        }
        
        .card-actions {
          display: flex;
        }
        
        .action-button {
          height: 28px;
          border-radius: 4px;
          font-weight: 500;
          cursor: pointer;
          border: none;
          transition: all 0.2s ease;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        
        .download-button {
          background-color: #F79222;
          color: white;
        }
        
        .download-button:hover {
          background-color: #e58420;
        }
        
        .download-button:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
        
        .download-button i {
          font-size: 14px;
          margin-right: 6px;
        }
        
        .payment-table-container {
          padding: 16px;
          overflow-x: auto;
        }
        
        .payment-table {
          width: 100%;
          border-collapse: collapse;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: 0 1px 2px rgba(0,0,0,0.05);
        }
        
        .payment-table th {
          background-color: #f8f9fa;
          text-align: left;
          padding: 14px 16px;
          font-weight: 600;
          color: #495057;
          border-bottom: 2px solid #dee2e6;
          white-space: nowrap;
        }
        
        .payment-table td {
          padding: 14px 16px;
          border-bottom: 1px solid #dee2e6;
          background-color: white;
        }
        
        .payment-table tr:hover td {
          background-color: #f8f9fa;
        }
        
        .loan-link {
          color: #F9394C;
          text-decoration: none;
          font-weight: 500;
          transition: all 0.2s ease;
        }
        
        .loan-link:hover {
          color: #e02e3e;
          text-decoration: underline;
        }
        
        .no-data-row td {
          padding: 40px 0;
          text-align: center;
        }
        
        .no-data-message {
          color: #6c757d;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .mr-2 {
          margin-right: 8px;
        }
        
        .alert {
          padding: 12px 16px;
          margin-bottom: 16px;
          border-radius: 4px;
        }
        
        .alert-danger {
          background-color: #ffebee;
          color: #F9394C;
          border: 1px solid #F9394C;
        }
        
        .alert-success {
          background-color: #e8f5e9;
          color: #2e7d32;
          border: 1px solid #4caf50;
        }
      `}</style>
    </div>
  );
};

export default Payment;
