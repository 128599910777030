import React from "react";
import { Loan } from "../../components/Loan";
import { Filter } from "../../components/Filter";
import { Loading } from "../../components/Loader";
import AppClass from "../../AppClass";
import moment from "moment";
import * as XLSX from "xlsx";
import toastMessage from "../../components/toastMessage";
import { Body, Footer, Header, Modal } from "../../components/Modal";
import Select from "react-select";
import { Link } from "react-router-dom";
import membersTemplate from "../../assets/MembersTemplate.xlsx";
import { Pagination } from "../../components/Pagination";
import CardCount from "../../components/CardCount/CardCount";
import { numberWithCommas } from "../../configs/formatCurrency";
import { connect } from "react-redux";

let copyData = [];

class LoanList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      loans: [],
      isLoading: false,
      message: "Please wait...",
      start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      pageOfItems: [],
      is_retrieve_setting: false,
      loan_ids: [],
      setting_id: "",
      template_modal: false,
      long_term_purpose: [],
      short_term_purpose: [],
      loan_products: [],
      loan_type: "",
      loan_product: "",
      loan_types: [],
      app_ids: [],
      totalItemsCount: 0,
      page: 1,
      limit: 10,
      summary: {},
    };
  }

  componentDidMount = async () => {
    this.handleInitial();
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.handleInitial();
    }
  }

  handleInitial = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    await this.get_loan_by_apps(true, f_apps);

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }

    this.getFiltersFromLCStorage();
  };

  getFiltersFromLCStorage = () => {
    const { filters } = this.props;

    this.setState({ ...filters });

    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== "") {
        this.setState({
          [key]: filters[key],
        });
      }
    });
  };

  get_loan_by_apps(isLoading, apps) {
    let app_ids = AppClass.get_app_ids(apps);

    this.get_loans(isLoading, app_ids);

    this.setState({ app_ids });
  }

  onChangeSacco(e) {
    this.setState({ appid: e, loan_id: undefined }, () => {
      this.get_interest_rate(true, e.value);
      this.get_loans(true);
      this.get_members(e.value);
    });
  }

  onChangeLoanType(e) {
    let value = e.target.value,
      loan_products = [],
      { long_term_purpose, short_term_purpose } = this.state;

    if (long_term_purpose && value === "Long-term Loans") {
      for (let product of long_term_purpose) {
        loan_products.push(product);
      }
    }

    if (long_term_purpose && value === "Short-term Loans") {
      for (let product of short_term_purpose) {
        loan_products.push(product);
      }
    }

    this.setState({
      loan_products,
      loan_type: value,
    });
  }

  onChangeLoanProduct(e) {
    this.setState({ loan_product: e.target.value });
  }

  get_interest_rate(isLoadingSetting, app_id) {
    this.setState({
      isLoadingSetting,
    });

    let response = {};
    const body = {
      app_id,
      model: "setting",
    };
    AppClass.get_setting_data(body)
      .then((_data) => {
        if (_data.data.length > 0) {
          let data = _data.data[0];

          let loan_types = [],
            short_term_purpose = [],
            long_term_purpose = [],
            keys = Object.keys(data);

          if (keys.includes("short_term")) {
            loan_types.push("Short-term Loans");
            short_term_purpose = data.short_term;
          }

          if (keys.includes("long_term")) {
            loan_types.push("Long-term Loans");
            long_term_purpose = data.long_term;
          }

          this.setState({
            setting_id: data._id,
            loan_types,
            long_term_purpose,
            short_term_purpose,
            isLoadingSetting: false,
          });
        } else {
          toastMessage("error", "Sacco settings is missing");
        }

        this.setState({ isLoadingSetting: false });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoadingSetting: false,
        });
      });
  }

  onChangeLoanID(e) {
    this.setState({ loan_id: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeMember(e) {
    this.setState({ member: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value, page: 1 }, () => {
      this.get_loans(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value, page: 1 }, () => {
      this.get_loans(true);
    });
  }

  get_loans(isLoading) {
    this.setState({ isLoading });

    let { appid, loan_id, start_date, end_date, apps, limit, page, member } =
      this.state;

    let _appid = "";

    if (!appid || !appid?.value) {
      _appid = AppClass.get_app_ids(apps);
    } else {
      _appid = [appid.value];
    }

    let response = {};
    let status = ["Accepted", "Disbursed", "Cleared", "Paused"];

    const body = {
      app_id: _appid,
      status,
      pageNumber: page,
      pageLimit: limit,
    };

    if (start_date !== undefined || end_date !== undefined) {
      body["fromDate"] = start_date;
      body["toDate"] = end_date;
    }

    if (loan_id && loan_id.value) {
      body["loan_id"] = loan_id.value;
    }

    if (member && member.value) {
      body["user_id"] = member.value;
    }

    AppClass.get_loandata(body)
      .then((res) => {
        const { data, pagination, summary } = res.data;

        this.setState({
          loans: data,
          summary,
          isLoading: false,
          totalItemsCount: pagination.totalRecords,
        });

        let loan_ids = [];

        for (let el of data) {
          loan_ids.push({
            label: el.loan_id,
            value: el.loan_id,
          });
        }
        this.setState({ loan_ids });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  delete_loan(id) {
    const input_data = {
      id,
      model: "loan",
    };

    AppClass.delete_data(input_data);
  }

  send_reminder(
    user_name,
    phone_number,
    outstanding_amount,
    loan_id,
    app_id,
    organization,
    purpose
  ) {
    let text = `Hello ${user_name}, This serves to kindly remind you that you have an outstanding loan amount of KSH ${outstanding_amount} on purpose ${purpose}, Sacco: ${organization} , Loan ID: ${loan_id} `;

    let option = {
      phonenumber: phone_number,
      text,
      appid: app_id,
    };

    let response = {};
    response.sms_sending_message = "Wait sending sms...";
    this.setState({ sending_sms: true, response });

    AppClass.sendSMS(option)
      .then((data) => {
        response.success = "SMS has been sent successfully to " + phone_number;
        this.setState({
          response,
          sending_sms: false,
        });
      })
      .catch((err) => {
        this.setState({});
        this.setState({
          response,
          sending_sms: false,
          isSubmitting: false,
        });
      });
  }

  onImportLoans(e) {
    const { files } = e.target;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */

      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const jsonData = this.convertToJson(data);

      let keys = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

      if (keys.length === 0) {
        return toastMessage("error", "Empty file format");
      }

      if (
        !keys.includes("Name") &&
        !keys.includes("Phone Number") &&
        !keys.includes("User ID") &&
        !keys.includes("Sacco") &&
        !keys.includes("Setting ID") &&
        !keys.includes("Sacco ID") &&
        !keys.includes("Requested Amount") &&
        !keys.includes("Balance") &&
        !keys.includes("Payment Duration") &&
        !keys.includes("Loan Product") &&
        !keys.includes("Loan Type") &&
        !keys.includes("Date")
      ) {
        return toastMessage("error", "Invalid loan file format");
      }

      this.setState({ isLoading: true });

      let length_data = 0;

      for (let i = 0; i < jsonData.length; i++) {
        if (
          jsonData[i]["Sacco"] &&
          jsonData[i]["Sacco ID"] &&
          jsonData[i]["User ID"] &&
          jsonData[i]["Setting ID"] &&
          jsonData[i]["Requested Amount"] &&
          jsonData[i]["Payment Duration"] &&
          jsonData[i]["Balance"] &&
          jsonData[i]["Name"] &&
          jsonData[i]["Loan Product"] &&
          jsonData[i]["Loan Type"] &&
          jsonData[i]["Status"] &&
          jsonData[i]["Date"]
        ) {
          length_data += 1;
          this.saveLoanData(jsonData[i], length_data - 1, i);
        }
      }
    };
    reader.readAsBinaryString(files[0]);
  }

  onImportLoanRepayments(e) {
    const { files } = e.target;
    const reader = new FileReader();
    this.setState({ isLoading: true });
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */

      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const jsonData = this.convertToJson(data);

      let keys = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

      if (keys.length === 0) {
        return toastMessage("error", "Empty file format");
      }

      if (
        !keys.includes("Name") &&
        !keys.includes("Phone Number") &&
        !keys.includes("User ID") &&
        !keys.includes("Sacco") &&
        !keys.includes("Setting ID") &&
        !keys.includes("Sacco ID") &&
        !keys.includes("Requested Amount") &&
        !keys.includes("Last Payment") &&
        !keys.includes("Principal") &&
        !keys.includes("Ending Balance") &&
        !keys.includes("Loan Product") &&
        !keys.includes("Loan Type") &&
        !keys.includes("Payment Duration") &&
        !keys.includes("Date")
      ) {
        return toastMessage("error", "Invalid loan repayment format");
      }

      let length_data = 0;

      for (let i = 0; i < jsonData.length; i++) {
        if (
          jsonData[i]["Sacco"] &&
          jsonData[i]["Sacco ID"] &&
          jsonData[i]["User ID"] &&
          jsonData[i]["Setting ID"] &&
          jsonData[i]["Requested Amount"] &&
          jsonData[i]["Last Payment"] &&
          jsonData[i]["Principal"] &&
          jsonData[i]["Ending Balance"] &&
          jsonData[i]["Name"] &&
          jsonData[i]["Loan Product"] &&
          jsonData[i]["Loan Type"] &&
          jsonData[i]["Payment Duration"] &&
          jsonData[i]["Date"]
        ) {
          length_data += 1;
          this.saveLoanData(jsonData[i], length_data - 1, i);
        }
      }
    };
    reader.readAsBinaryString(files[0]);
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    this.setState({
      count_new_saving: result.length - 1,
    });

    return result; //JSON
  }

  onPressFileImport(name, e) {
    this.refs[name].click();
  }

  saveLoanData = async (data, length, i) => {
    const input_data = {
      app_id: data["Sacco ID"],
      loan_id: data["Loan ID"] || this.generateRandomID(data["Sacco"]),
      organization: data["Sacco"],
      model: "loan",
      req_amount: data["Requested Amount"],
      amount: data["Balance"] || data["Ending Balance"],
      last_payment: data["Last Payment"] || 0,
      payment_info: JSON.stringify({
        exp_interest_rate: data["Interest"] || 0,
        exp_principle_payment: data["Principal"] || 0,
      }),
      interestrate: data["Setting ID"],
      payment_duration: data["Payment Duration"] || 12,
      total_interest: data["Total Interest"] || 0,
      total_payment: data["Total Payment"] || 0,
      user_id: data["User ID"] || "-",
      user_name: data["Name"] || "-",
      phone_number: data["Phone Number"] || "-",
      status: data["Status"] || "Accepted",
      available: true,
      date: new Date(data["Date"]).getTime(),
      purpose: data["Loan Product"],
      loan_type: data["Loan Type"],
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      AppClass.add_data(input_data)
        .then(async (data) => {
          if (length === i) {
            toastMessage("success", "Data has been uploaded successfully");
            this.setState({ isLoading: false });

            this.get_loan_by_apps(true, this.props.apps);
          }
        })
        .catch((err) => {
          if (length === i) {
            toastMessage("error", "Data has been failed to be uploaded");
            this.setState({ isLoading: false });
          }
        });
    }
  };

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleOpenModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  get_app_setting(app_id) {
    this.setState({
      isLoadingSetting: true,
    });

    const body = {
      app_id,
      model: "setting",
    };
    return AppClass.get_setting_data(body);
  }

  onImportMembers(e) {
    const { files } = e.target;
    const reader = new FileReader();
    this.setState({ isLoadingMember: true });

    reader.onload = async (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */

      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const jsonData = this.convertToJson(data);

      let keys = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

      if (keys.length === 0) {
        return toastMessage("error", "Empty members");
      }

      if (
        !keys.includes("Name") &&
        !keys.includes("Phone Number") &&
        !keys.includes("User ID") &&
        !keys.includes("Sacco") &&
        !keys.includes("Sacco ID")
      ) {
        return toastMessage("error", "Invalid member file format");
      }

      if (this.state.setting_id === "") {
        return toastMessage("error", "Missing setting ID");
      }

      if (this.state.loan_product === "Choose") {
        return toastMessage("error", "Missing loan product");
      }

      if (this.state.loan_type === "Choose") {
        return toastMessage("error", "Missing loan type");
      }

      let results = [];

      for (let i = 0; i < jsonData.length; i++) {
        const el = jsonData[i];

        if (el["Name"] && el["User ID"] && el["Phone Number"]) {
          results.push({
            Name: el["Name"],
            "User ID": el["User ID"],
            "Phone Number": el["Phone Number"],
            Sacco: this.state.appid.label,
            "Sacco ID": this.state.appid.value,
            "Setting ID": this.state.setting_id,
            "Loan Type": this.state.loan_type,
            "Loan Product": this.state.loan_product,
          });

          if (i === jsonData.length - 1) {
            this.setState({ isLoadingSetting: false });
          }
        }
      }

      this.setState({
        template_data: results,
      });
      // this.generateLoanTemplate(results);
      // this.generateLoanRepaymentTemplate(results);
    };
    reader.readAsBinaryString(files[0]);
  }

  generateLoanRepaymentTemplate(data) {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet([{}], {
      header: [
        "Name",
        "User ID",
        "Phone Number",
        "Sacco",
        "Sacco ID",
        "Setting ID",
        "Loan Type",
        "Loan Product",
        "Requested Amount",
        "Interest",
        "Last Payment",
        "Principal",
        "Balance",
        "Total Interest",
        "Total Payment",
        "Date",
      ],
    });
    XLSX.utils.sheet_add_json(sheet, data, { origin: "A3" });
    XLSX.utils.book_append_sheet(workbook, sheet);
    XLSX.writeFile(
      workbook,
      `epesiLoanRepaymentTemplate${new Date().getTime()}.xlsx`
    );
  }

  generateLoanTemplate(data) {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet([{}], {
      header: [
        "Name",
        "User ID",
        "Phone Number",
        "Sacco",
        "Sacco ID",
        "Setting ID",
        "Loan Type",
        "Loan Product",
        "Requested Amount",
        "Balance",
        "Payment Duration",
        "Status",
        "Balance",
        "Date",
      ],
    });
    XLSX.utils.sheet_add_json(sheet, data, { origin: "A3" });
    XLSX.utils.book_append_sheet(workbook, sheet);
    XLSX.writeFile(workbook, `epesiLoanTemplate${new Date().getTime()}.xlsx`);
  }

  randomString(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateRandomID(sacco_name) {
    return (
      sacco_name.split(" ")[0] +
      "-" +
      this.randomString(2).toUpperCase() +
      "" +
      new Date().getTime().toString().substring(0, 4)
    );
  }

  handleResetFilters() {
    this.setState(
      {
        member: undefined,
        loan_id: undefined,
        start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      () => {
        window.location.reload();
      }
    );
  }

  handleChangeLimit(e) {
    this.setState(
      {
        limit: e.target.value,
        page: 1,
      },
      () => {
        this.get_loans(true);
      }
    );
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.get_loans(true);
      }
    );
  }

  get_members(app_id) {
    const { diladmin } = this.props;
    let body = {
      app_id,
      from_dil: diladmin + "",
    };
    AppClass.get_member(body)
      .then((data) => {
        let members = [];

        for (let el of data.data.data) {
          members.push({
            label: el.user_name,
            value: el.user_id,
          });
        }
        this.setState({
          members,
          isLoading: false,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const {
      apps,
      response,
      loans,
      isLoading,
      message,
      appid,
      loan_id,
      start_date,
      end_date,
      sending_sms,
    } = this.state;
    const csvData = [
      [
        "Loan ID",
        "Sacco",
        "Borrower",
        "Principal",
        "Disbursed Amount",
        "Outstanding Amount",
        "Issued At",
        "Next Payment Date On",
        "End Date of Loan Repayment",
        "Status",
      ],
    ];

    for (var i = 0; i < loans.length; i++) {
      csvData.push([
        loans[i].loan_id,
        loans[i].organization,
        loans[i].user_name,
        `KES ${numberWithCommas(loans[i].req_amount)}`,
        `KES ${numberWithCommas(loans[i].disbursed_amount)}`,
        `KES ${numberWithCommas(loans[i].amount)}`,
        moment(loans[i].date).format("ll"),
        loans[i].amount > 0
          ? moment(loans[i].date).add(31, "days").format("ll")
          : "-",
        moment(loans[i].date)
          .add(loans[i].payment_duration, "months")
          .format("ll"),
        loans[i].status,
      ]);
    }

    return (
      <div>
        <Filter
          appid={appid}
          loan_id={loan_id}
          onChangeLoanID={this.onChangeLoanID.bind(this)}
          apps={apps}
          loans={copyData}
          members={this.state.members}
          member={this.state.member}
          response={response}
          start_date={start_date}
          end_date={end_date}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          loan_ids={this.state.loan_ids}
          showReset={this.state?.appid?.value}
          handleResetFilters={this.handleResetFilters.bind(this)}
          onChangeMember={this.onChangeMember.bind(this)}
        />
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : (
          <>
            <div className="col-md-12 ">
              <div className="row">
                <CardCount
                  count={this.state.summary.noOfLoans}
                  title="Number of Loans"
                />
                <CardCount
                  count={
                    "KES " +
                    numberWithCommas(this.state.summary.totalDisbursedAmount)
                  }
                  title="Total Disbursement"
                />
                <CardCount
                  count={
                    "KES " +
                    numberWithCommas(this.state.summary.totalRepayments)
                  }
                  title="Total Payment"
                />
                <CardCount
                  count={
                    "KES " +
                    numberWithCommas(this.state.summary.totalOutStandingAmount)
                  }
                  title="Total Outstanding Balance"
                />
              </div>
            </div>
            <Loan
              loans={loans}
              csvData={csvData}
              send_reminder={this.send_reminder.bind(this)}
              response={response}
              sending_sms={sending_sms}
              onPressFileImport={(e) =>
                this.onPressFileImport("loansUploader", e)
              }
              onPressFileImportRepayment={(e) =>
                this.onPressFileImport("repaymentUploader", e)
              }
              handleOpenTemplateModal={() =>
                this.handleOpenModal("template_modal")
              }
              fromDate={this.state.start_date}
              toDate={this.state.end_date}
              app_id={this.state.appid?.value}
              ztoken={this.props.ztoken}
              user_id={this.state.member?.value}
              pageLimit={this.state.limit}
            />
            <Pagination
              totalItemsCount={this.state.totalItemsCount}
              page={this.state.page}
              limit={this.state.limit}
              handleChangeLimit={this.handleChangeLimit.bind(this)}
              handlePagination={this.handlePagination.bind(this)}
            />
          </>
        )}

        <input
          type="file"
          ref="loansUploader"
          style={{ display: "none" }}
          accept=".xlsx, .xls, .csv"
          onChange={(e) => this.onImportLoans(e)}
        />

        <input
          type="file"
          ref="repaymentUploader"
          style={{ display: "none" }}
          accept=".xlsx, .xls, .csv"
          onChange={(e) => this.onImportLoanRepayments(e)}
        />

        <Modal show={this.state.template_modal}>
          <Header>
            <h5 class="modal-title">Loan Template</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("template_modal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="panel">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Sacco <span className="required">(*)</span>
                    </label>
                    <Select
                      options={apps}
                      value={this.state.sacco}
                      onChange={this.onChangeSacco.bind(this)}
                    />
                    <span className="text-danger">
                      {this.state.response.sacco}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Loan Type <span className="required">(*)</span>
                    </label>
                    <select
                      className="form-control"
                      value={this.state.loan_type}
                      onChange={this.onChangeLoanType.bind(this)}
                    >
                      <option>Choose</option>
                      {this.state.loan_types.map((item, i) => {
                        return <option key={i}>{item}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {this.state.response.loan_type}
                    </span>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Loan Product <span className="required">(*)</span>
                    </label>
                    <select
                      options={apps}
                      className="form-control"
                      value={this.state.loan_product}
                      onChange={this.onChangeLoanProduct.bind(this)}
                    >
                      <option>Choose</option>
                      {this.state.loan_products.map((item, i) => {
                        return <option key={i}>{item.type}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {this.state.response.loan_product}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Upload Member(s) <span className="required">(*)</span>
                    </label>
                    <input
                      value={this.state.member_list}
                      className="form-control"
                      type="file"
                      onChange={this.onImportMembers.bind(this)}
                    />
                    <span className="text-danger">
                      {this.state.response.member}
                    </span>
                  </div>
                </div>
              </div>
              <div className="alert alert-info">
                Upload members and loan template will be generate automatically.
                Don't have member template?{" "}
                <Link
                  target={"_blank"}
                  to={membersTemplate}
                  download={`MembersTemplate.xlsx`}
                >
                  Download Member Template
                </Link>
              </div>
              {this.state.isLoadingSetting && (
                <center>
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: 20 }}
                  />
                </center>
              )}
            </div>
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              // disabled={!this.state.template_data}
              onClick={() =>
                this.generateLoanTemplate(this.state.template_data)
              }
            >
              Download Loan Template
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              // disabled={!this.state.template_data}
              onClick={() =>
                this.generateLoanRepaymentTemplate(this.state.template_data)
              }
            >
              Download Loan Repayment Template
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(LoanList);
