import React from "react";
import { Card } from "../Card";
import Select from "react-select";
import { terms } from "../../data/settings";
import { numberWithCommas } from "../../configs/formatCurrency";

const ApplyNew = ({
  payment_products,
  handleformonchange,
  response,
  payment_duration,
  calculateLoanAmortization,
  total_interest,
  term_payment,
  total_payments,
  disabled,
  onChangeSacco,
  sacco,
  application_id,
  handleApplyData,
  isSubmitting,
  apps,
  term,
  interestrate,
  available,
  results,
  saved_loan,
  purpose,
  method,
  contribution_rate,
  contribution_amount,
  number_tyres,
  loan_type,
  long_term_purpose,
  short_term_purpose,
  onChangeLoanType,
  loan_type_value,
  onChangePurpose,
  fixed_amount,
  fleet,
  fleets,
  handleChangeFleet,
  loan_products,
  loan_period,
  start_date_loan,
  total_cost_loan,
  number_payment,
  loan_period_term,
  loan_amount,
  onChangeAmount,
  maximum_loan_amount,
  members,
  member,
  onChangeMember,
  role,
  need_guarantor,
  guaranted_amount,
  onChangeGuarantorAmount,
  onChangeGuarantor,
  guarantor,
  isLoading,
  user_id,
  handleFile,
  guarantors,
  last_saving,
  onAddGuarantor,
  onRemoveGuarantor,
  products,
  product,
  onChangeSavingProduct,
  onChangeFees,
  fees,
  totalFees,
  purpose_value,
  onChangeTotalPayment,
  onChangeTotalInterest,
  payment_product_id,
  onChangePaymentProduct,
}) => {
  let term_name =
    loan_period_term === "Daily"
      ? "months(s)"
      : loan_period_term === "Weekly"
      ? "week(s)"
      : loan_period_term === "Monthly"
      ? "months"
      : "year(s)";

  return (
    <div>
      <Card className="col-md-8">
        <div className="panel-heading card-title">
          <span><i className="fa fa-money-bill-wave"></i> Loan Application Form</span>
        </div>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-content">
              <i className="fa fa-spinner fa-spin fa-2x"></i>
              <p>Fetching data, please wait...</p>
            </div>
          </div>
        )}
        
        <div className="panel-body">
          {/* MEMBER SECTION */}
          <div className="section-header">
            <h4><i className="fa fa-building"></i> Member Details</h4>
            <hr />
          </div>
          
          <div className="row">
            {/* SACCO */}
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <i className="fa fa-building"></i> Sacco <span className="required">*</span>
                </label>
                <Select
                  value={sacco}
                  options={apps}
                  name="fleet"
                  onChange={onChangeSacco}
                  className="form-control-select"
                />
                <span className="text-danger">{response.sacco}</span>
              </div>
            </div>

            {/* MEMBER */}
            {role === "admin" && (
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    <i className="fa fa-user"></i> Member <span className="required">*</span>
                  </label>
                  <Select
                    value={member}
                    options={members}
                    name="member"
                    onChange={onChangeMember}
                    className="form-control-select"
                  />
                  <span className="text-danger">{response.user_id}</span>
                </div>
              </div>
            )}

            {/* LOAN ID */}
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <i className="fa fa-id-card"></i> Loan ID <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  value={application_id}
                  disabled
                  onChange={handleformonchange}
                />
                <span className="text-danger">{response.application_id}</span>
              </div>
            </div>
            
            {/* FLEET */}
            <div className="col-md-6">
              <div className="form-group">
                <label><i className="fa fa-truck"></i> Fleet</label>
                <Select
                  value={fleet}
                  onChange={handleChangeFleet}
                  options={fleets}
                  name="fleet"
                  className="form-control-select"
                />
                <span className="text-danger">{response.fleet}</span>
              </div>
            </div>
          </div>
          
          {/* LOAN PRODUCT DETAILS SECTION */}
          <div className="section-header mt-4">
            <h4><i className="fa fa-money-bill-wave"></i> Loan Product Details</h4>
            <hr />
          </div>
          
          <div className="row">
            {/* LOAN TYPE */}
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <i className="fa fa-money-bill-wave"></i> Loan Type <span className="required">*</span>
                </label>
                <select
                  className="form-control"
                  name="loan_type_value"
                  onChange={onChangeLoanType}
                  data-live-search="true"
                  id="loan_type"
                  value={loan_type_value}
                >
                  <option selected disabled>
                    Choose loan type
                  </option>
                  {loan_type.map((item, i) => {
                    return <option key={i}>{item}</option>;
                  })}
                </select>
                <span className="text-danger">{response.loan_type}</span>
              </div>
            </div>

            {/* LOAN PRODUCT */}
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <i className="fa fa-money-bill-wave"></i> Loan Product <span className="required">*</span>
                </label>
                <select
                  className="form-control"
                  onChange={onChangePurpose}
                  name="purpose"
                  disabled={!loan_type_value}
                  data-live-search="true"
                  value={purpose_value}
                >
                  <option disabled selected>
                    Choose loan product
                  </option>
                  {loan_products.map((item, i) => {
                    return <option key={i}>{item.type}</option>;
                  })}
                </select>
              </div>
            </div>
            
            {/* INTEREST RATE - MOVED HERE */}
            {purpose.type && (
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    <i className="fa fa-chart-line"></i> Annual Interest Rate(%) <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={purpose.interest_rate}
                    name="rate"
                    min={0}
                    max={100}
                    step={0.1}
                    onChange={handleformonchange}
                  />
                  <span className="text-danger">{response.interest_rate}</span>
                </div>
              </div>
            )}
            
            {/* SAVING PRODUCT - MOVED HERE */}
            {purpose.type && (
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    <i className="fa fa-money-bill-wave"></i> Saving Product <span className="required">*</span>
                  </label>
                  <Select
                    options={products}
                    onChange={onChangeSavingProduct}
                    value={product}
                    className="form-control-select"
                  />
                  <span className="text-danger">
                    {/* Determine saving balance from different saving products */}
                  </span>
                </div>
              </div>
            )}
            
            {/* PAYMENT FREQUENCY */}
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <i className="fa fa-clock"></i> Payment Frequency <span className="required">*</span>
                </label>
                <select
                  className="form-control"
                  value={loan_period_term}
                  name="loan_period_term"
                  onChange={handleformonchange}
                  data-live-search="true"
                >
                  {terms.map((t) => {
                    return <option>{t}</option>;
                  })}
                </select>
                <span className="text-danger">{response.loan_period_term}</span>
              </div>
            </div>

            {/* LOAN PERIOD */}
            {loan_period_term !== "" && (
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    <i className="fa fa-calendar-alt"></i>
                    {` Loan period in ${
                      loan_period_term === "Monthly"
                        ? "Month(s)"
                        : loan_period_term === "Yearly"
                        ? "Year(s)"
                        : ""
                    }`}
                    <span className="required">{' '}*</span>
                  </label>
                  <input
                    className="form-control"
                    value={loan_period}
                    name="loan_period"
                    type="number"
                    onChange={handleformonchange}
                    disabled
                  />
                  <span className="text-danger">{response.loan_period}</span>
                </div>
              </div>
            )}

            {/* PAYMENT PRODUCT */}
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <i className="fa fa-money-bill-wave"></i> Payment Product
                </label>
                <select
                  className="form-control"
                  onChange={onChangePaymentProduct}
                  name="payment_product_id"
                  value={payment_product_id || ''}
                >
                  <option value="" >
                    Choose payment product
                  </option>
                  {payment_products.map((item, i) => {
                    return <option key={i} value={item.value}>{item.label}</option>;
                  })}
                </select>
              </div>
            </div>
            
            {/* START DATE */}
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <i className="fa fa-calendar-alt"></i> Start date of loan <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  value={start_date_loan}
                  name="start_date_loan"
                  type="date"
                  onChange={handleformonchange}
                />
                <span className="text-danger">{response.start_date_loan}</span>
              </div>
            </div>
          </div>

          {/* NO OF TYRES */}
          {purpose?.type?.toLowerCase() === "tyre" && (
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  <i className="fa fa-truck"></i> Number of Tyres <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  value={number_tyres}
                  name="number_tyres"
                  onChange={handleformonchange}
                />
                <span className="text-danger">{response.number_tyres}</span>
              </div>
            </div>
          )}

          {purpose.type && (
            <>
              {/* LOAN DETAILS SECTION */}
              <div className="section-header mt-4">
                <h4><i className="fa fa-money-bill-wave"></i> Loan Details</h4>
                <hr />
              </div>
              
              <div className="row">
                {/* CONTRIBUTION */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <i className="fa fa-money-bill-wave"></i> Contribution <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      value={purpose.contribution}
                      name="contribution_rate"
                      onChange={handleformonchange}
                      placeholder="0"
                    />
                    <span className="text-danger">{response.contribution_rate}</span>
                  </div>
                </div>

                {/* PAYMENTS */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label><i className="fa fa-money-bill-wave"></i> Initial Payment</label>
                    <input
                      className="form-control"
                      type="number"
                      name="total_payments"
                      onChange={onChangeTotalPayment}
                      value={total_payments}
                    />
                    <span className="text-danger">
                      {response.total_payments}
                    </span>
                  </div>
                </div>

                {/* LOAN AMOUNT */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <i className="fa fa-money-bill-wave"></i> Loan Amount <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="loan_amount"
                      onChange={onChangeAmount}
                      value={loan_amount}
                    />
                    <span className="text-danger">
                      {loan_amount > maximum_loan_amount
                        ? `Your loan amount must be in between of KES ${last_saving} and ${maximum_loan_amount}`
                        : `Maximum loan amount : KES ${maximum_loan_amount}`}
                    </span>
                    <span className="text-danger">
                      {response.loan_amount}
                    </span>
                  </div>
                </div>

                {/* INTEREST */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <i className="fa fa-chart-line"></i> Interest <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="total_interest"
                      onChange={onChangeTotalInterest}
                      value={total_interest}
                    />
                    <span className="text-danger">
                      {response.total_interest}
                    </span>
                  </div>
                </div>
              </div>

              {/* GUARANTOR SECTION */}
              {loan_amount > last_saving &&
                loan_amount < maximum_loan_amount &&
                need_guarantor?.guarantor?.length > 0 && (
                <div className="section-header mt-4">
                  <h4><i className="fa fa-handshake"></i> Guarantor Details</h4>
                  <hr />
                </div>
              )}

              {loan_amount > last_saving &&
                loan_amount < maximum_loan_amount &&
                need_guarantor?.guarantor?.map((item, i) => {
                  return (
                    <div key={i} className="row mb-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            <i className="fa fa-user"></i> Select Guarantor{" "}
                            <span className="required">(*)</span>
                          </label>
                          <Select
                            value={need_guarantor.guarantor[i]}
                            options={guarantors}
                            name="guarantor"
                            onChange={(e) => onChangeGuarantor("label", i, e)}
                            className="form-control-select"
                          />
                          {need_guarantor?.guarantor[i]?.isChecking && (
                            <span className="text-danger">
                              Checking eligibility...
                            </span>
                          )}
                          {need_guarantor?.guarantor[i]?.last_saving &&
                            need_guarantor?.guarantor[i]?.last_saving
                              ?.free_deposit <= 0 && (
                              <span className="text-danger">
                                {` No enough funds to contribute`}
                              </span>
                            )}
                          {need_guarantor?.guarantor[i]?.last_saving
                            ?.free_deposit > 0 && (
                            <span className="text-danger">
                              {`Maxiumum contribution from ${need_guarantor?.guarantor[i].label} is: KES ${need_guarantor?.guarantor[i]?.last_saving?.free_deposit}`}
                            </span>
                          )}
                          <span className="text-danger">
                            {need_guarantor.guarantor[i].error?.label}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <i className="fa fa-money-bill-wave"></i> Guaranted Amount{" "}
                            <span className="required">(*)</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="guaranted_amount"
                            onChange={(e) =>
                              onChangeGuarantorAmount("amount", i, e)
                            }
                            value={need_guarantor.guarantor[i].amount}
                          />
                          <span className="text-danger">
                            {need_guarantor.guarantor[i].error?.amount}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-2" style={{ marginTop: 25 }}>
                        {need_guarantor?.guarantor.length - 1 !== i ? (
                          <button
                            className="btn btn-danger"
                            onClick={() => onRemoveGuarantor(i)}
                          >
                            <i className="fa fa-times"></i> Remove
                          </button>
                        ) : (
                          need_guarantor?.guarantor[i]?.last_saving?.free_deposit > 0 &&
                          need_guarantor?.guarantor[i]?.amount > 0 &&
                          !need_guarantor?.guarantor[i]?.isChecking && (
                            <button
                              className="btn btn-success"
                              onClick={onAddGuarantor}
                            >
                              <i className="fa fa-plus"></i> Add
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
            </>
          )}

          {/* FEES & ATTACHMENTS SECTION */}
          <div className="section-header mt-4">
            <h4><i className="fa fa-money-bill-wave"></i> Fees & Attachments</h4>
            <hr />
          </div>
          
          <div className="row">
            {/* FILE UPLOAD - Changed from col-md-12 to col-md-6 */}
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label><i className="fa fa-file-upload"></i> Attach File</label>
                <input
                  className="form-control"
                  name="file"
                  type="file"
                  onChange={handleFile}
                  multiple
                />
                <span className="text-danger">{response.start_date_loan}</span>
              </div>
            </div>

            {/* FEES */}
            {Object.keys(fees).map((el, i) => {
              return (
                <div className="col-md-4" key={i}>
                  <div className="form-group">
                    <label style={{ textTransform: "capitalize" }}>
                      <i className="fa fa-money-bill-wave"></i> {el}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={fees[el] + ""}
                      onChange={(e) => onChangeFees(el, e)}
                      placeholder="Enter amount"
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {/* ACTION BUTTONS */}
          {sacco !== "" && purpose.type && (
            <div className="row mt-4">
              <div className="col-md-4">
                <button
                  className="btn btn-calculate btn-block"
                  onClick={calculateLoanAmortization}
                >
                  <i className="fa fa-calculator"></i> Calculate
                </button>
              </div>
              {(total_cost_loan > 0 ||
                (need_guarantor.guarantor &&
                  Object.keys(need_guarantor.guarantor).length > 0 &&
                  last_saving >= loan_amount &&
                  loan_amount < maximum_loan_amount)) && (
                <div className="col-md-4 col-md-offset-4">
                  <button
                    className="btn btn-apply btn-block"
                    disabled={isSubmitting}
                    onClick={handleApplyData}
                  >
                    <i className="fa fa-paper-plane"></i> {isSubmitting ? "Sending..." : "Apply"}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>

      {/* SUMMARY CARD */}
      {total_cost_loan > 0 && (
        <>
          <Card className="col-md-4">
            <div className="panel-heading card-title">
              <span><i className="fa fa-file-invoice"></i> LOAN SUMMARY</span>
            </div>

            <div className="panel-body summary-container">
              <div className="summary-item">
                <div className="summary-label">
                  <i className="fa fa-calendar-alt"></i> <span>{term} Payment</span>
                </div>
                <div className="summary-value">
                  KES {numberWithCommas(Math.ceil(term_payment))}
                </div>
              </div>
              
              <div className="summary-item">
                <div className="summary-label">
                  <i className="fa fa-clock"></i> <span>Repayment Period</span>
                </div>
                <div className="summary-value">
                  {number_payment} {term_name}
                </div>
              </div>
              
              <div className="summary-item">
                <div className="summary-label">
                  <i className="fa fa-money-bill-wave"></i> <span>Loan Amount</span>
                </div>
                <div className="summary-value">
                  KES {numberWithCommas(Math.ceil(loan_amount))}
                </div>
              </div>

              <div className="summary-item">
                <div className="summary-label">
                  <i className="fa fa-chart-line"></i> <span>Total Interest</span>
                </div>
                <div className="summary-value">
                  KES {numberWithCommas(Math.ceil(total_interest))}
                </div>
              </div>

              <div className="summary-item">
                <div className="summary-label">
                  <i className="fa fa-receipt"></i> <span>Total Fees</span>
                </div>
                <div className="summary-value">
                  KES {numberWithCommas(Math.ceil(totalFees || 0))}
                </div>
              </div>

              <div className="summary-item total">
                <div className="summary-label">
                  <i className="fa fa-coins"></i> <span>Total Amount Payable</span>
                </div>
                <div className="summary-value">
                  KES {numberWithCommas(Math.ceil(total_cost_loan))}
                </div>
              </div>
            </div>
          </Card>
          
          {/* SCHEDULE */}
          <Card className="col-md-12">
            <div className="panel-heading card-title">
              <span><i className="fa fa-calendar-alt"></i> PAYMENT SCHEDULE</span>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{textAlign: "center"}}>Date</th>
                    <th style={{textAlign: "right"}}>Beginning Balance</th>
                    <th style={{textAlign: "right"}}>Payment</th>
                    <th style={{textAlign: "right"}}>Principal</th>
                    <th style={{textAlign: "right"}}>Interest</th>
                    <th style={{textAlign: "right"}}>Ending Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {results.length > 0 &&
                    results.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td style={{textAlign: "center"}}>{item.payment_date}</td>
                          <td style={{textAlign: "right"}}>{numberWithCommas(Math.ceil(item.beginning_balance))}</td>
                          <td style={{textAlign: "right"}}>{numberWithCommas(Math.ceil(item.term_payment))}</td>
                          <td style={{textAlign: "right"}}>{numberWithCommas(Math.ceil(item.principal))}</td>
                          <td style={{textAlign: "right"}}>{numberWithCommas(Math.ceil(item.term_interest))}</td>
                          <td style={{textAlign: "right"}}>{numberWithCommas(Math.ceil(item.ending_balance))}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Card>
        </>
      )}
      
      {/* Add CSS for better styling */}
      <style jsx>{`
        .section-header {
          margin-top: 20px;
          margin-bottom: 15px;
        }
        .section-header h4 {
          color: #F9394C;
          font-weight: 600;
        }
        .form-group label {
          font-weight: 600;
          color: #333;
        }
        .required {
          color: #F9394C;
        }
        .summary-container {
          padding: 15px;
          background-color: #f9f9f9;
          border-radius: 4px;
        }
        .summary-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #eee;
        }
        .summary-item.total {
          border-top: 2px solid #F9394C;
          border-bottom: none;
          margin-top: 20px;
          padding-top: 15px;
          font-weight: 700;
        }
        .summary-label {
          display: flex;
          align-items: center;
          color: #555;
          font-weight: 600;
        }
        .summary-label i {
          color: #F9394C;
          margin-right: 10px;
          width: 20px;
          text-align: center;
        }
        .summary-value {
          font-weight: 700;
          font-size: 16px;
          color: #F79222;
          text-align: right;
        }
        .summary-item.total .summary-value {
          font-size: 18px;
          color: #F9394C;
        }
        .form-control-select {
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        .table-responsive {
          overflow-x: auto;
        }
        .mb-3 {
          margin-bottom: 15px;
        }
        .mt-4 {
          margin-top: 20px;
        }
        .panel-heading {
          background-color: #f8f9fa;
          border-bottom: 2px solid #F9394C;
          padding: 12px 15px;
        }
        .card-title {
          font-weight: 600;
          color: #F9394C;
        }
        .btn-calculate, .btn-apply {
          background-color: #F79222;
          border-color: #F79222;
          color: white;
        }
        .btn-calculate:hover, .btn-apply:hover {
          background-color: #e07b10;
          border-color: #e07b10;
        }
        .btn-primary {
          background-color: #F79222;
          border-color: #F79222;
          color: white;
        }
        .btn-primary:hover {
          background-color: #e07b10;
          border-color: #e07b10;
        }
        .btn-default {
          background-color: #f8f9fa;
          border-color: #ddd;
          color: #333;
        }
        .btn-default:hover {
          background-color: #e9ecef;
        }
        .form-control:focus {
          border-color: #F79222;
          box-shadow: 0 0 0 0.2rem rgba(247, 146, 34, 0.25);
        }
        .alert-info {
          background-color: #f8f9fa;
          border-color: #F9394C;
          color: #333;
        }
        .text-danger {
          color: #F9394C;
        }
        table thead th {
          background-color: #f8f9fa;
          color: #333;
        }
        .table-striped tbody tr:nth-of-type(odd) {
          background-color: rgba(247, 146, 34, 0.05);
        }
        .table-bordered {
          border: 1px solid #ddd;
        }
        .table-bordered th, .table-bordered td {
          border: 1px solid #ddd;
        }
        .loading-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 10;
          border-radius: 4px;
        }
        .loading-content {
          text-align: center;
          background-color: white;
          padding: 20px 30px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-left: 4px solid #F79222;
        }
        .loading-content i {
          color: #F79222;
          margin-bottom: 10px;
        }
        .loading-content p {
          margin: 0;
          font-weight: 500;
          color: #333;
        }
      `}</style>
    </div>
  );
};

export default ApplyNew;
