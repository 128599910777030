import Axios from "axios";
import React from "react";
import HOST_URL from "../../configs/api";
import { Card } from "../Card";
import { Loading } from "../Loader";
import toastMessage from "../toastMessage";
import AppClass from "../../AppClass";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import CardCount from "../CardCount/CardCount";
import { Filter } from "../Filter";
import { Body, Footer, Header, Modal } from "../Modal";
import "./styles.css";
import DropdownButton from "../DropdownButton";

class Messages extends React.Component {
  state = {
    data: [],
    error: {},
    limit: 10,
    page: 1,
    totalItemsCount: 0,
    fromDate: moment().subtract("60", "days").format("YYYY-MM-DD"),
    toDate: moment().add(1, "d").format("YYYY-MM-DD"),
    summary: {},
    members: [],
    // Message-related state
    showMessageModal: false,
    messageText: "",
    selectedRecipients: [],
    messageSending: false,
    messageAction: 'create', // 'create', 'view', 'draft'
    messages: [], // To store messages
    showMemberDropdown: false,
    searchTerm: '',
    selectedMessage: null, // For editing/viewing a message
    isDeleting: false,
    isSending: false,
    saveAsDraft: false,
    user: {},
  };

  componentDidMount = async () => {
    await this.getSaccoApps();
    let data = AppClass.get_user_sessions();
    if (data?.ztoken) {
      this.setState({ user: data });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.apps !== this.props.apps) {
      this.getAppIds(this.props.apps);
      this.setState({ appid: this.props.apps[0] }, () => {
        this.getData(true);
      });
    }
  };

  getData = async (isLoading, app_ids, memberId) => {
    this.setState({ isLoading });

    const { fromDate, toDate } = this.state;
    const { user_id, role } = this.props;

    let params = {
      app_id: JSON.stringify(app_ids),
      fromDate,
      toDate,
    };

    if (memberId) {
      params.userId = memberId;
    }

    if (role !== "admin") {
      params.user_id = user_id;
    }

    const options = {
      method: "GET",
      url: HOST_URL.url + "/messages",
      params,
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };

    Axios(options)
      .then((response) => {
        const { data, summary } = response.data;
        this.setState({ isLoading: false, messages: data, summary });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toastMessage("error", error);
      });
  };

  getAppIds = async (apps) => {
    const app_ids = await AppClass.get_app_ids(apps);
    return app_ids;
  };

  onChangeText(field, e) {
    this.setState({
      [field]: e.target ? e?.target?.value : e,
    });

    if (field === "appid") {
      this.getData(true, [e.value], this.state?.member?.value);
      this.get_members(e.value);
    }

    if (field === "member") {
      this.getData(true, [this.state.appid], e.value);
    }
  }

  handleChangeLimit(e) {
    this.setState({
      limit: e.target.value,
      page: 1,
    });
  }

  handlePagination(page) {
    this.setState({
      page,
    });
  }

  onChangeStartingDate(e) {
    const app_id = this.state?.appid?.value
      ? [this.state?.appid?.value]
      : undefined;

    this.setState({ fromDate: e.target.value, page: 1 }, () => {
      this.getData(true, app_id, this.state?.member?.value);
    });
  }

  onChangeEndDate(e) {
    const app_id = this.state?.appid?.value
      ? [this.state?.appid?.value]
      : undefined;
    this.setState({ toDate: e.target.value, page: 1 }, () => {
      this.getData(true, app_id, this.state?.member?.value);
    });
  }

  handleResetFilters() {
    this.setState(
      {
        sacco: undefined,
        fromDate: moment().subtract("1", "month").format("yyyy-MM-dd"),
        toDate: moment().add("1", "d").format("yyyy-MM-dd"),
      },
      () => {
        window.location.reload();
      }
    );
  }

  // New method to handle opening the message modal
  handleOpenMessageModal(preSelectedMember = null) {
    let selectedRecipients = [];
    if (preSelectedMember) {
      selectedRecipients = [preSelectedMember];
    }
    this.setState({ 
      showMessageModal: true, 
      modalTitle: "Send Message to Members",
      selectedRecipients,
      messageText: "",
      messageAction: 'create',
      saveAsDraft: false
    });
  }

  handleCloseModal(modal) {
    this.setState({ 
      [modal]: false,
      modalItem: null,
      selectedMessage: null,
      messageText: "",
      selectedRecipients: []
    });
  }

  // New method to handle message text changes
  handleMessageTextChange(e) {
    this.setState({ messageText: e.target.value });
  }

  // Update the handleRecipientChange method to handle the searchable select
  handleRecipientChange(selectedMember) {
    const isAlreadySelected = this.state.selectedRecipients.some(
      recipient => recipient.value === selectedMember.value
    );
    
    if (!isAlreadySelected) {
      this.setState({
        selectedRecipients: [...this.state.selectedRecipients, selectedMember]
      });
    }
  }
  
  // New method to remove a recipient
  removeRecipient(recipientToRemove) {
    this.setState({
      selectedRecipients: this.state.selectedRecipients.filter(
        recipient => recipient.value !== recipientToRemove.value
      )
    });
  }
  
  // New method to select all members
  selectAllMembers() {
    this.setState({
      selectedRecipients: [...this.state.members]
    });
  }
  
  // New method to clear all selected recipients
  clearAllRecipients() {
    this.setState({
      selectedRecipients: []
    });
  }

  handleCreateMessage = async () => {
    const { messageText, selectedRecipients, appid, selectedMessage, saveAsDraft } = this.state;
    
    if (!messageText.trim()) {
      return toastMessage("error", "Message text cannot be empty");
    }

    if (messageText.length > 160) {
      return toastMessage("error", "Message text cannot be more than 160 characters");
    }
    
    if (selectedRecipients.length === 0) {
      return toastMessage("error", "Please select at least one recipient");
    }

    this.setState({ messageSending: true });

    const recipientPhones = selectedRecipients.map(recipient => recipient.phone || recipient.value);

    try {
      // Determine if this is a create or update operation
      const method = selectedMessage ? "PUT" : "POST";
      const url = selectedMessage 
        ? `${HOST_URL.url}/messages/${selectedMessage._id}`
        : `${HOST_URL.url}/messages`;

      console.log('user session data', this.state.user);

      const response = await Axios({
        method,
        url,
        data: {
          content: messageText,
          recipients: recipientPhones,
          app_id: appid.value,
          created_by_id: this.state.user._id,
        },
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });

      // If not saving as draft, send the message immediately
      if (!saveAsDraft) {
        await this.handleSendMessage(response.data.message._id);
      } else {
        toastMessage(
          "success", 
          response.data.message ||
          "Message saved successfully"
        );
      }

      this.setState({ 
        messageSending: false,
        showMessageModal: false,
        messageText: "",
        selectedRecipients: [],
        selectedMessage: null
      });
      
      // Refresh messages list
      this.getData(true, [this.state.appid.value]);
    } catch (error) {
      this.setState({ messageSending: false });
      toastMessage(
        "error",
        error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message || 
        "An error occurred while saving the message"
      );
    }
  };

  handleSendMessage = async (messageId) => {
    try {
      const response = await Axios({
        method: "POST",
        url: `${HOST_URL.url}/messages/${messageId}/send`,
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });
      
      toastMessage(
        "success",
        response.data.message ||
        "Message sent successfully"
      );
      this.getData(true, [this.state.appid.value]);
    } catch (error) {
      toastMessage(
        "error",
        error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message || 
        "An error occurred while sending the message"
      );
    }
  };

  handleDeleteMessage = async (messageId) => {
    if (!window.confirm('Are you sure you want to delete this message?')) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await Axios({
        method: "DELETE",
        url: `${HOST_URL.url}/messages/${messageId}`,
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });

      toastMessage("success", "Message deleted successfully");
      this.getData(true, [this.state.appid.value]);
    } catch (error) {
      toastMessage("error", error);
    } finally {
      this.setState({ isDeleting: false });
    }
  };

  handleEditMessage = (message) => {
    // Map recipients to member objects, falling back to phone number if no match found
    const mappedRecipients = message.recipients.map(recipient => {
      const matchingMember = this.state.members.find(member => member.phone === recipient.phone);
      return matchingMember || {
        value: recipient.phone,
        label: recipient.name || recipient.phone,
        phone: recipient.phone
      };
    });

    this.setState({
      showMessageModal: true,
      modalTitle: "Edit Draft Message",
      selectedMessage: message,
      messageText: message.content,
      selectedRecipients: mappedRecipients,
      messageAction: 'edit'
    });
  };

  getMessageActions = (message) => {
    const baseActions = [
      {
        text: 'View Details',
        icon: 'fa-eye',
        iconColor: '#17a2b8',
        onClick: () => window.location.href = `/home/admin/messages/${message._id}`
      }
    ];

    // Add edit and other actions only for draft messages
    if (message.status === 'draft') {
      return [
        {
          text: 'Edit Draft',
          icon: 'fa-edit',
          iconColor: '#28a745',
          onClick: () => this.handleEditMessage(message)
        },
        {
          text: 'Send Now',
          icon: 'fa-paper-plane',
          iconColor: '#007bff',
          onClick: () => this.handleSendMessage(message._id)
        },
        {
          text: 'Delete',
          icon: 'fa-trash',
          iconColor: '#dc3545',
          onClick: () => this.handleDeleteMessage(message._id)
        },
        ...baseActions
      ];
    }

    return baseActions;
  };

  get_members(app_id) {
    if (!app_id) return;
    const { diladmin } = this.props;
    let body = {
      app_id,
      from_dil: diladmin + "",
    };

    this.setState({ isLoadingMembers: true, member: undefined });

    AppClass.get_member(body)
      .then((data) => {
        let members = [];

        for (let el of data.data.data) {
          members.push({
            label: el.user_name,
            value: el.user_id,
            phone: el.phone_number
          });
        }

        this.setState({
          members,
          isLoadingMembers: false,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoadingMembers: false,
        });
      });
  }

  getSaccoApps() {
    let f_apps = [];

    for (let el of this.props.apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    this.setState({ apps: f_apps });

    if (f_apps.length > 0) {
      this.setState({ appid: f_apps[0] }, () => {
        this.getData(true, [f_apps[0]?.value]);
        this.get_members(f_apps[0]?.value);
      });
    }
  }

  render() {
    return (
      <div>
        <Filter
          appid={this.state.appid}
          apps={this.state.apps}
          start_date={this.state.fromDate}
          end_date={this.state.toDate}
          members={this.state.members}
          member={this.state.member}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          onChangeSacco={(e) => this.onChangeText("appid", e)}
          showReset={this.state?.appid?.value}
          handleResetFilters={this.handleResetFilters.bind(this)}
          onChangeMember={(e) => this.onChangeText("member", e)}
          isLoadingMembers={this.state.isLoadingMembers}
        />

        <div>
          {this.state.isLoading ? (
            <Loading icon="fa fa-spinner fa-spin" />
          ) : (
            <>
              <div className="col-md-12 ">
                <div className="row">
                  <CardCount
                    count={this.state?.summary?.total_messages || 0}
                    title="Total Messages"
                    icon="fas fa-envelope"
                    color="primary"
                  />
                  <CardCount
                    count={this.state?.summary?.sent_messages || 0}
                    title="Sent Messages"
                    icon="fas fa-paper-plane"
                    color="success"
                  />
                  <CardCount
                    count={this.state?.summary?.draft_messages || 0}
                    title="Draft Messages"
                    icon="fas fa-file-alt"
                    color="info"
                  />
                </div>
              </div>
              <Card className="col-md-12">
                <div className="panel-heading card-title min-height-100">
                  <div className="title-section">
                    <i className="fas fa-envelope-open-text title-icon"></i>
                    <span>Messages</span>
                  </div>
                  <div className="card-menus">
                    <button 
                      className="btn btn-compose" 
                      onClick={() => this.handleOpenMessageModal()}
                    >
                      <i className="fa fa-pen-to-square mr-1"></i> Compose Message
                    </button>
                  </div>
                </div>
                
                <div className="panel-body" style={{
                  padding: "15px",
                  overflowX: "auto",
                }}>
                  <table 
                    className="table table-bordered"
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      overflowX: "auto",
                      marginBottom: "80px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Message</th>
                        <th>Recipients</th>
                        <th>Status</th>
                        <th>Created By</th>
                        <th style={{ textAlign: "center" }}>Date</th>
                        <th style={{ textAlign: "center" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.messages.length > 0 ? (
                        this.state.messages.map((message, i) => (
                          <tr key={i}>
                            <td>{message.content}</td>
                            <td>{message.recipients?.length || 0} recipients</td>
                            <td>
                              <span className={`badge ${
                                message.status === 'sent' ? 'badge-success' : 
                                message.status === 'sending' ? 'badge-warning' :
                                message.status === 'failed' ? 'badge-danger' :
                                'badge-info'
                              }`}>
                                {message.status}
                              </span>
                            </td>
                            <td>{message.created_by?.user_name || 'N/A'}</td>
                            <td style={{ textAlign: "center" }}>
                              {moment(message.created_at).format("lll")}
                            </td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                              <DropdownButton 
                                buttonText="Select Actions"
                                actions={this.getMessageActions(message)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} align="center">
                            No Messages Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalItemsCount={this.state.messages.length}
                  page={this.state.page}
                  limit={this.state.limit}
                  handleChangeLimit={this.handleChangeLimit.bind(this)}
                  handlePagination={this.handlePagination.bind(this)}
                />
              </Card>
            </>
          )}
        </div>

        {/* Message Modal */}
        <Modal show={this.state.showMessageModal} size="lg">
          <Header>
            <h5 className="modal-title">{this.state.modalTitle}</h5>
            <button
              type="button"
              className="close"
              onClick={() => this.handleCloseModal("showMessageModal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="form-group">
              <label className="recipient-label">Recipients</label>
              <div className="recipient-actions mb-3">
                <button 
                  className="btn btn-sm btn-primary recipient-action-btn" 
                  onClick={() => this.selectAllMembers()}
                >
                  <i className="fa fa-users mr-1"></i> Select All Members
                </button>
                <button 
                  className="btn btn-sm btn-outline-secondary recipient-action-btn" 
                  onClick={() => this.clearAllRecipients()}
                >
                  <i className="fa fa-times mr-1"></i> Clear Selection
                </button>
              </div>
              
              {/* Selected Recipients Display */}
              <div className="selected-recipients-container mb-3">
                {this.state.selectedRecipients.length > 0 ? (
                  <div className="selected-recipients-card">
                    <div className="selected-recipients-header">
                      <span className="selected-count">
                        <i className="fa fa-check-circle mr-1"></i>
                        Selected Recipients ({this.state.selectedRecipients.length})
                      </span>
                    </div>
                    <div className="selected-recipients-body">
                      {this.state.selectedRecipients.map((recipient, index) => (
                        <div key={index} className="recipient-badge">
                          <span className="recipient-name">{recipient.label}</span>
                          <span className="recipient-phone">{recipient.phone}</span>
                          <button 
                            type="button" 
                            className="recipient-remove" 
                            onClick={() => this.removeRecipient(recipient)}
                            title="Remove recipient"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="no-recipients-alert">
                    <i className="fa fa-info-circle mr-2"></i>
                    <span>No recipients selected. Please select at least one recipient.</span>
                  </div>
                )}
              </div>
              
              <div className="recipient-selector" ref={this.setWrapperRef}>
                <div className="search-select-container">
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search and select members..."
                    onClick={() => this.setState({ showMemberDropdown: true })}
                    onChange={(e) => {
                      this.setState({ 
                        searchTerm: e.target.value,
                        showMemberDropdown: true 
                      });
                    }}
                    value={this.state.searchTerm || ''}
                  />
                  {this.state.showMemberDropdown && (
                    <div className="search-results">
                      {this.state.members
                        // Filter out already selected members
                        .filter(member => 
                          !this.state.selectedRecipients.some(
                            selected => selected.value === member.value
                          )
                        )
                        // Then filter by search term
                        .filter(member => 
                          !this.state.searchTerm || 
                          member.label.toLowerCase().includes((this.state.searchTerm || '').toLowerCase()) ||
                          member.phone.includes(this.state.searchTerm || '')
                        )
                        .map((member, index) => (
                          <div 
                            key={index} 
                            className="search-result-item"
                            onClick={() => {
                              this.handleRecipientChange(member);
                              this.setState({ 
                                searchTerm: '',
                                showMemberDropdown: false
                              });
                            }}
                          >
                            <div className="member-info">
                              <span className="member-name">{member.label}</span>
                              <span className="member-phone">{member.phone}</span>
                            </div>
                          </div>
                        ))}
                      {this.state.members
                        .filter(member => 
                          !this.state.selectedRecipients.some(
                            selected => selected.value === member.value
                          )
                        )
                        .filter(member => 
                          !this.state.searchTerm || 
                          member.label.toLowerCase().includes((this.state.searchTerm || '').toLowerCase()) ||
                          member.phone.includes(this.state.searchTerm || '')
                        ).length === 0 && (
                          <div className="no-results">
                            {this.state.searchTerm ? 
                              `No unselected members found matching "${this.state.searchTerm}"` : 
                              'All members have been selected'}
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            <div className="form-group mt-4">
              <label className="message-label">Message</label>
              <textarea
                className="form-control message-textarea"
                rows="5"
                value={this.state.messageText}
                onChange={(e) => this.handleMessageTextChange(e)}
                placeholder="Type your message here..."
                disabled={this.state.messageAction === 'view'}
              ></textarea>
              <small className="message-counter text-muted">
                {this.state.messageText.length} characters
              </small>
            </div>
          </Body>
          <Footer>
            <button
              type="button"
              className="btn btn-cancel"
              onClick={() => this.handleCloseModal("showMessageModal")}
            >
              <i className="fa fa-times mr-1"></i> Close
            </button>
            
            {this.state.messageAction !== 'view' && (
              <>
                <button
                  type="button"
                  className="btn btn-draft ml-2"
                  onClick={() => {
                    this.setState({ saveAsDraft: true }, () => {
                      this.handleCreateMessage();
                    });
                  }}
                  disabled={this.state.messageSending}
                >
                  {this.state.messageSending ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <><i className="fa fa-save mr-1"></i> Save as Draft</>
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-send ml-2"
                  onClick={() => {
                    this.setState({ saveAsDraft: false }, () => {
                      this.handleCreateMessage();
                    });
                  }}
                  disabled={this.state.messageSending}
                >
                  {this.state.messageSending ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <><i className="fa fa-paper-plane mr-1"></i> Send Now</>
                  )}
                </button>
              </>
            )}
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default Messages;
