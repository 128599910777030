import React from "react";
import HOST_URL from "../../../configs/api";
import Axios from "axios";
import toastMessage from "../../../components/toastMessage";
import { saveAs } from "file-saver";

class Loans extends React.Component {
  state = {
    selectedData: {},
  };
  handleShowTransaction(i) {
    const key = "showTrans_" + i;

    this.setState({
      [key]: !this.state[key],
    });
  }
  handleGenerateCSVReport() {
    this.setState({ isSubmitting: true });
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/aggregate/loans/pdf`,
      responseType: "blob",
      params: {
        fromDate: this.props.fromDate,
        toDate: this.props.toDate,
        app_id: this.props.appid,
      },
      headers: {
        authorization: `Bearer ${this.props.ztoken}`,
        "Content-Type": "application/pdf",
      },
    };

    Axios(options)
      .then((data) => {
        this.downloadPDF(data.data);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", "Unable to generate loan statement report");
      });
  }

  handleGenerateReport() {
    this.setState({ isSubmitting: true });
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/aggregate/loans/pdf`,
      responseType: "blob",
      params: {
        fromDate: this.props.fromDate,
        toDate: this.props.toDate,
        app_id: this.props.appid,
      },
      headers: {
        authorization: `Bearer ${this.props.ztoken}`,
        "Content-Type": "application/pdf",
      },
    };

    Axios(options)
      .then((data) => {
        this.downloadPDF(data.data);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", "Unable to generate loan statement report");
      });
  }

  downloadPDF(file) {
    try {
      toastMessage("success", "Downloaded successful");
      this.setState({ isSubmitting: false });
      const pdfBlob = new Blob([file], { type: "application/pdf" });
      saveAs(
        pdfBlob,
        this.props.memberName +
          "" +
          this.props.fromDate +
          "" +
          this.props.toDate +
          ".pdf"
      );
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("Error downloading PDF:", JSON.stringify(error));
    }
  }

  render() {
    return (
      <>
        <br />
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Loan Type</th>
                <th style={{ textAlign: "right" }}>Interest Rate</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map((el, i) => {
                return (
                  <tr key={i}>
                    <td>{el.purpose}</td>
                    <td style={{ textAlign: "right" }}>{el.rate}%</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Loans;
