const getTotalFees = (fees = {}) => {
  console.log('fees', fees)
  // If fees is already an object, use it directly
  // If it's a string, parse it
  const feeObj = typeof fees === 'string' ? JSON.parse(fees) : fees;

  return Object.keys(feeObj).reduce((acc, fee) => acc + Number(feeObj[fee]), 0);
};

export default getTotalFees;
