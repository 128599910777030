import React from "react";
import { Card } from "../Card";

const WithdrawForm = ({
  amount,
  isSubmitting,
  handleSaveData,
  balance,
  isLoading,
  error,
  fleets,
  onChangeText,
}) => (
  <div>
    <div>
      {error.errorMessage && (
        <div className="alert alert-danger">
          <span>{error.errorMessage}</span>
        </div>
      )}
    </div>
    <Card>
      <div className="panel-body">
        <div className="form-group">
          <label>Balance</label>
          <input
            className="form-control"
            type="text"
            name="balance"
            value={"KES " + balance}
            disabled
          />
          <span className="text-danger">{error.balance}</span>
        </div>
        <div className="form-group">
          <label>Narration</label>
          <select
            className="form-control"
            name="narration"
            onChange={(e) => onChangeText("narration", e)}
          >
            <option value=""></option>
            <option value="Management Fee">Management Fee</option>
            <option value="General Withdrawal">Normal Withdrawal</option>
            {/* <option value="Loan Repayment">Loan Repayment</option> */}
            <option value="Registration Fee">Registration Fee</option>
            <option value="TLB Fee">TLB Fee</option>
            <option value="System Fee">System Fee</option>
          </select>
          <span className="text-danger">{error.narration}</span>
        </div>
        <div className="form-group">
          <label>Fleet</label>
          <select
            className="form-control"
            name="fleet"
            onChange={(e) => onChangeText("fleet", e)}
          >
            <option value=""></option>
            {fleets?.map((fleet, index) => (
              <option key={index} value={fleet.value}>
                {fleet.label}
              </option>
            ))}
          </select>
          <span className="text-danger">{error.fleet}</span>
        </div>
        <div className="form-group">
          <label>Amount</label>
          <input
            className="form-control"
            type="number"
            value={amount}
            onChange={(e) => onChangeText("amount", e)}
            max={balance}
            min={0}
          />
          <span className="text-danger">{error.amount}</span>
        </div>
        <div className="text-center" style={{ marginTop: 25 }}>
          <button
            className="btn btn-primary"
            disabled={isSubmitting || isLoading ? true : false}
            onClick={handleSaveData}
          >
            {isSubmitting ? "Sending..." : "Submit"}
          </button>
        </div>
      </div>
    </Card>
  </div>
);
export default WithdrawForm;
