import React, { useState } from "react";
import { Card } from "../Card";
import moment from "moment";
import { numberWithCommas } from "../../configs/formatCurrency";
import HOST_URL from "../../configs/api";
import toastMessage from "../toastMessage";
import Axios from "axios";
import { saveAs } from "file-saver";

const List = ({
  savings,
  saving_permission,
  onViewSaving,
  onPressFileImport,
  search_text,
  handleSearch,
  handleShowReversalModal,
  onDownloadTemplate,
  admin,
  fromDate,
  toDate,
  app_id,
  ztoken,
  user_id,
}) => {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const downloadFile = (file, type) => {
    try {
      toastMessage("success", "Download successful");
      setIsSubmitting(false);
      const fileBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        fileBlob,
        `savings_${fromDate}_${toDate}.${type}`
      );
    } catch (error) {
      setIsSubmitting(false);
      toastMessage('error', `Error downloading file: ${error.message}`);
    }
  }

  const handleGenerateReport = (type='csv') => {
    setIsSubmitting(true);
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/savings/${type}`,
      responseType: "blob",
      params: {
        fromDate,
        toDate,
        app_id: JSON.stringify([app_id]),
      },
      headers: {
        authorization: `Bearer ${ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };
    if (user_id) {
      options.params.user_id = user_id;
    }

    Axios(options)
      .then((data) => {
        downloadFile(data.data, type);
      })
      .catch((error) => {
        setIsSubmitting(false);
        toastMessage("error", "Unable to generate savings report");
      });
  }

  return (
    <Card className="col-md-12">
      <div className="panel-heading card-title">
        <span>Savings</span>
        <div className="card-menus">
          <div className="input-default">
            <input
              style={{
                height: 25,
                marginRight: 5,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#ddd",
              }}
              placeholder="Search..."
              value={search_text}
              onChange={handleSearch}
            />
          </div>
          {admin && (
            <>
              <div className="icon-default"
                disabled={true}
                // onClick={onDownloadTemplate}
              >
                <b>Savings Template</b>
              </div>
              <div className="icon-default" disabled={true}>
                <b>Import Savings</b>
              </div>
              
              <div className="icon-default">
                <div
                  onClick={() => handleGenerateReport('csv')}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Downloading, Please wait..." : "Download CSV Report"}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="panel-body table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Member</th>
              <th>Fleet</th>
              <th>Product</th>
              <th>Last Transaction</th>
              <th>Interest</th>
              <th>Total Balance</th>
              <th>System Date</th>
              <th>Transaction Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {savings.length > 0 ? (
              savings.map((item, i) => {
                return (
                  <tr key={i}>
                    <td onClick={() => onViewSaving(item)}>{item.tickno}</td>
                    <td onClick={() => onViewSaving(item)}>{item.user_name}</td>
                    <td onClick={() => onViewSaving(item)}>
                      {item.fleet && item.fleet !== "" ? item.fleet : "-"}
                    </td>
                    <td onClick={() => onViewSaving(item)}>
                      {item.product_name && item.product_name !== ""
                        ? (item.product_name || '-').toUpperCase()
                        : "-"}
                    </td>
                    <td
                      id="tooltip"
                      onClick={() => onViewSaving(item)}
                      data-toggle={
                        item.last_deposit < 0 ? "Withdraw" : "Deposit"
                      }
                      title={
                        item.last_deposit < 0
                          ? "Withdrawal of KES " + Math.abs(item.last_deposit)
                          : "Deposit of KES " + item.last_deposit
                      }
                    >
                      <i
                        className={
                          item.last_deposit < 0 ? "fa fa-arrow-down" : ""
                        }
                        style={{ color: "red", marginRight: 5 }}
                      />
                      {item.balBroughtForward === true ? "(Bal C/F) " : ""}
                      KES {numberWithCommas(item.last_deposit)}
                    </td>
                    <td onClick={() => onViewSaving(item)}>
                      KES {item.total_interest ? item.total_interest : 0}
                    </td>
                    <td onClick={() => onViewSaving(item)}>
                      <b>
                        KES{" "}
                        {numberWithCommas(
                          item.total_saving - item.total_withdrawn
                        )}
                      </b>
                    </td>
                    <td onClick={() => onViewSaving(item)}>
                      {moment(item.date).format("lll")}
                    </td>
                    <td onClick={() => onViewSaving(item)}>
                      {moment(item.client_record_date).format("lll")}
                    </td>
                    {saving_permission === "admin" ? (
                      <td width="5%" align="center">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={() => onViewSaving(item)}
                            title="View Transaction"
                          >
                            <i className="fa fa-eye" />
                          </button>
                          {item.terminalId === "Dashboard" && (
                            <button
                              onClick={() => handleShowReversalModal(item)}
                              title="Reverse transaction"
                            >
                              <i
                                style={{ marginRight: "0.5rem" }}
                                class="fa fa-reply-all"
                                aria-hidden="true"
                              ></i>
                            </button>
                          )}
                        </div>
                      </td>
                    ) : saving_permission === "member" ? (
                      <td>-</td>
                    ) : (
                      <td>-</td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} align="center">
                  No data here
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};
export default List;
